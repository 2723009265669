import React, { Component} from "react";
import { connect } from 'react-redux';
import { Link, Redirect } from "react-router-dom";

import { Row, Col, Form, Input, Button, Checkbox, Alert} from "antd";

import LeftContent from "../leftContent";
import * as actions from "../../../../redux/auth/authActions";
import HeaderLanguge from "../../../../layout/components/header/HeaderLanguage";
import {withTranslation} from "react-i18next";

class ConfirmEmail extends Component {

    componentDidMount() {
        if (this.props &&  this.props.match && this.props.match.params) {

            let {id, token} = this.props.match.params;
            if (id && token) {
                this.props.onConfirmEmail(id, token);
            }
        }
    };


    render() {
        const {t} = this.props;
        let authRedirect = null;
        if (this.props.confirmStatus) {
            authRedirect = <Redirect to = {{
                pathname: "/authentication/login",
                state: {message: 'Email Confirm Successful', messageType: 'Email Confirm'}
            }}/>
        }
        return (
            <Row gutter={[32, 0]} className="hp-authentication-page" style={{height:"100vh"}}>
                <LeftContent />
                {authRedirect}
                <Col md={12}>
                    <Row>
                        <Col offset={20} span={4} align="right">
                            <Row style={{"paddingTop": "20px"}}>
                                <HeaderLanguge lang="en" />
                            </Row>
                        </Col>
                    </Row>
                    <Row className="hp-h-100" align="middle" justify="center" gutter={[32, {xxl: 11, xl:15, lg: 20, md: 20, sm: 24}]}>
                        <Col
                            span={20}
                            className="hp-px-sm-8 hp-pt-24 hp-pb-48"
                        >
                            <h1 className="hp-mb-sm-0">{t('Email Confirmation')}</h1>
                            <p className="hp-mt-sm-0 hp-mt-8 hp-text-color-black-60">
                                {t("Email verification was not successful.  Please try to login.")}
                            </p>

                            <div className="hp-form-info">
                            <span className="hp-text-color-black-80 hp-text-color-dark-40 hp-caption hp-mr-4">
                                {t("Go back to")}
                            </span>

                                <Link
                                    to="/authentication/login"
                                    className="hp-text-color-primary-1 hp-text-color-dark-primary-2 hp-caption"
                                >
                                    {t("Login")}
                                </Link>
                            </div>
                        </Col>
                    </Row>
                </Col>
            </Row>
        );
    }


}

const mapStateToProps = state => {
    return {
        error: state.auth.error,
        errorMessage: state.auth.errorMessage,
        confirmStatus: state.auth.emailConfirmed,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onConfirmEmail: (id, token) => dispatch( actions.confirmEmail({id: id, token: token})),
    };
};

export default connect( mapStateToProps, mapDispatchToProps )( withTranslation()(ConfirmEmail) );
import * as actionTypes from "./referralActions";
import { updateObject } from "../../shared/utility";


const initialState = {
    startDate: null,
    endDate: null,
    referrals: null,
    royalties: null
};


const referralsLoadFinished = (state, action) => {
    return updateObject(
        state,
        action.payload
    );
};


const reducer = ( state = initialState, action ) => {
    switch ( action.type ) {
        case actionTypes.REFERRAL_LOAD_FINISH: return referralsLoadFinished(state, action);
        default:
            return state;
    }
};

export default reducer;
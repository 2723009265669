import {put} from "@redux-saga/core/effects";
import * as actions from "./tariffActions";
import * as systemActions from "../system/systemActions";
import axios from "axios";

export function* tariffLoadSaga(action){
    let config = {
        headers: {
            Authorization: "Bearer " + action.token,
            Accept: "application/json"
        }
    };

    try {
        const response = yield axios.get(
            "/v1/tariff",
            config
        );
        yield put(actions.tariffLoadFinish(response.data.result));

    } catch (error) {

    }
}

export function* addTariff(action) {
    let config = {
        headers: {
            Authorization: "Bearer " + action.token,
            Accept: "application/json"
        }
    }

    let response = null;

    try {

        if (!action.payload.id) {
            response = yield axios.post(
                "/v1/tariff",
                action.payload,
                config
            );
        } else {
            response = yield axios.put(
                "/v1/tariff/" + action.payload.id,
                action.payload,
                config
            );
        }

        if (response.data.result && response.data.result.success) {
            yield put(actions.tariffLoad(action.token));
            yield put(systemActions.systemNotification(response.data.result.message));
        } else {
            yield put(systemActions.axiosError(response.data.result));
        }

    } catch (error) {
        console.log("Error:", error)
    }
}

export function* deleteTariffSaga(action) {

    let config = {
        headers: {
            Authorization: "Bearer " + action.token,
            Accept: "application/json"
        }
    }

    try {
        const response = yield axios.delete(
            "/v1/tariff/" + action.payload,
            config
        );

        if (response.data.result && response.data.result.success) {
            yield put(actions.tariffLoad(action.token));
            yield put(systemActions.systemNotification(response.data.result.message));
        } else {
            yield put(systemActions.axiosError(response.data.result));
        }

    } catch (error) {

    }
}

export function* changeTariffSaga(action) {

    let config = {
        headers: {
            Authorization: "Bearer " + action.token,
            Accept: "application/json"
        }
    };

    try {
        const response = yield axios.get(
            "/v1/tariff/change?id=" + action.payload,
            config
        );

        if (response.data.result && response.data.result.success) {
            yield put(systemActions.systemNotification(response.data.result.message));
        } else {
            yield put(systemActions.axiosError(response.data.result));
        }

    } catch (error) {

    }
}
import * as actionTypes from "./profileActions";
import { updateObject } from "../../shared/utility";

const initialState = {
    error: null,
    errorMessage: null,
    loading: false,
    profile: null,
    notificationSettings: null
};

const profileLoadFinished = (state, action) => {
    return updateObject(
        state,
        {
            loading: false,
            profile: action.payload
        }
    );
};

const notificationSettingsLoadFinished = (state, action) => {
    return updateObject(
        state,
        {
            notificationSettings: action.data
        }
    );
}

const reducer = ( state = initialState, action ) => {
    switch ( action.type ) {
        case actionTypes.PROFILE_LOAD_FINISH: return profileLoadFinished(state, action);
        case actionTypes.PROFILE_GET_NOTIFICATION_SETTINGS_FINISH: return notificationSettingsLoadFinished(state, action);
        default:
            return state;
    }
};

export default reducer;
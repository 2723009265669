export const REFERRAL_CLIENTS_LOAD = 'REFERRAL_CLIENTS_LOAD';
export const REFERRAL_CLIENTS_LOADED = 'REFERRAL_CLIENTS_LOADED';

export const referralClientsLoad = (token, start, end) => {
    return {
        type: REFERRAL_CLIENTS_LOAD,
        token: token,
        start: start,
        end: end
    }
};

export const referralClientsLoaded = (payload) => {
    return {
        type: REFERRAL_CLIENTS_LOADED,
        payload: payload
    }
};
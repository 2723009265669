export const GET_CLIENT_LIST = "GET_CLIENT_LIST";
export const GET_CLIENT_LIST_DONE = "GET_CLIENT_LIST_DONE";

export const GET_CLIENT_COMPANIES = "GET_CLIENT_COMPANIES";
export const GET_CLIENT_COMPANIES_DONE = "GET_CLIENT_COMPANIES_DONE";

export const CLEAR_CLIENT_COMPANIES = "CLEAR_CLIENT_COMPANIES";

export const GET_COMPANY_LOGS_LOADING = "GET_COMPANY_LOGS_LOADING";
export const GET_COMPANY_LOGS = "GET_COMPANY_LOGS";
export const GET_COMPANY_LOGS_DONE = "GET_COMPANY_LOGS_DONE";

export const GET_USER_COMPANIES = "GET_USER_COMPANIES";
export const GET_USER_LOGS = "GET_USER_LOGS";
export const GET_USER_COMPANY_FILTER = "GET_USER_COMPANY_FILTER";
export const GET_USER_COMPANY_FILTER_DONE = "GET_USER_COMPANY_FILTER_DONE";
export const CLEAR_USER_COMPANY_FILTERS = "CLEAR_USER_COMPANY_FILTERS";

export const GET_COMPANY_TOTALS = "GET_COMPANY_TOTALS";
export const GET_COMPANY_TOTALS_DONE = "GET_COMPANY_TOTALS_DONE";

export const GET_COMPANY_DAILY = "GET_COMPANY_DAILY";
export const GET_COMPANY_DAILY_DONE = "GET_COMPANY_DAILY_DONE";

export const GET_COMPANY_PLATFORM = "GET_COMPANY_PLATFORM";
export const GET_COMPANY_PLATFORM_DONE = "GET_COMPANY_PLATFORM_DONE";

export const GET_REQUEST_STATISTIC = "GET_REQUEST_STATISTIC";
export const GET_REQUEST_STATISTIC_DONE = "GET_REQUEST_STATISTIC_DONE";

export const GET_TOP_REQUEST_STATISTIC = "GET_TOP_REQUEST_STATISTIC";
export const GET_TOP_REQUEST_STATISTIC_DONE = "GET_TOP_REQUEST_STATISTIC_DONE";

export const getClientList = (token) => {
    return {
        type: GET_CLIENT_LIST,
        token: token,
    }
};

export const getClientListDone = (payload) => {
    return {
        type: GET_CLIENT_LIST_DONE,
        payload: payload
    }
};

export const clearClientCompanies = () => {
    return {
        type: CLEAR_CLIENT_COMPANIES
    }
}

export const getClientCompanies = (token, client) => {
    return {
        type: GET_CLIENT_COMPANIES,
        token: token,
        client: client,
    }
}

export const getClientCompaniesDone = (payload) => {
    return {
        type: GET_CLIENT_COMPANIES_DONE,
        payload: payload
    }
};

export const getCompanyLogs = (token, payload) => {
    return {
        type: GET_COMPANY_LOGS,
        token: token,
        payload: payload
    }
};

export const getCompanyLogsLoading = () => {
    return {
        type: GET_COMPANY_LOGS_LOADING
    }
};

export const getCompanyLogsDone = (payload) => {
    return {
        type: GET_COMPANY_LOGS_DONE,
        payload: payload
    }
};

export const getUserCompanies = (token) => {
    return {
        type: GET_USER_COMPANIES,
        token: token
    }
};

export const getUserLogs = (token, payload) => {
    return {
        type: GET_USER_LOGS,
        token: token,
        payload: payload
    }
};

export const getUserCompanyFilter = (token, companyId, startDate, endDate) => {
    return {
        type: GET_USER_COMPANY_FILTER,
        token: token,
        companyId: companyId,
        startDate: startDate,
        endDate: endDate
    }
};

export const getClientCompanyFilterDone = (payload) => {
    return {
        type: GET_USER_COMPANY_FILTER_DONE,
        payload: payload
    }
}

export const clearUserCompanyFilters = () => {
    return {
        type: CLEAR_USER_COMPANY_FILTERS,
    }
}

export const loadCompanyTotals = (token, payload) => {
    return {
        type: GET_COMPANY_TOTALS,
        token: token,
        payload: payload
    }
};

export const loadCompanyTotalsDone = (payload) => {
    return {
        type: GET_COMPANY_TOTALS_DONE,
        payload: payload
    }
}

export const loadCompanyDaily = (token, payload) => {
    return {
        type: GET_COMPANY_DAILY,
        token: token,
        payload: payload
    }
};

export const loadCompanyDailyDone = (payload) => {
    return {
        type: GET_COMPANY_DAILY_DONE,
        payload: payload
    }
}

export const loadCompanyPlatforms = (token, payload) => {
    return {
        type: GET_COMPANY_PLATFORM,
        token: token,
        payload: payload
    }
};

export const loadCompanyPlatformsDone = (payload) => {
    return {
        type: GET_COMPANY_PLATFORM_DONE,
        payload: payload
    }
};

export const loadRequestStatistic = (token, payload) => {
    return {
        type: GET_REQUEST_STATISTIC,
        token: token,
        payload: payload
    }
};

export const loadRequestStatisticDone = (payload) => {
    return {
        type: GET_REQUEST_STATISTIC_DONE,
        payload: payload
    }
}

export const loadTopRequestStatistic = (token, payload) => {
    return {
        type: GET_TOP_REQUEST_STATISTIC,
        token: token,
        payload: payload
    }
};

export const loadTopRequestStatisticDone = (payload) => {
    return {
        type: GET_TOP_REQUEST_STATISTIC_DONE,
        payload: payload
    }
}
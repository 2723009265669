export const AXIOS_START = "AXIOS_START";
export const AXIOS_END = "AXIOS_END";
export const AXIOS_ERROR = "AXIOS_ERROR";

export const SYSTEM_NOTIFICATION = "SYSTEM_NOTIFICATION";
export const SYSTEM_NOTIFICATION_END = "SYSTEM_NOTIFICATION_END";

export const axiosStart = () => {
    return {
        type: AXIOS_START
    }
};

export const axiosEnd = () => {
    return {
        type: AXIOS_END
    }
};

export const axiosError = (data) => {
    return {
        type: AXIOS_ERROR,
        data: data.message
    }
}

export const systemNotification = (data) => {
    return {
        type: SYSTEM_NOTIFICATION,
        data: data
    }
};

export const systemNotificationEnd = () => {
    return {
        type: SYSTEM_NOTIFICATION_END
    }
};

export const PROMO_LOAD = 'PROMO_LOAD';
export const PROMO_LOAD_FINISH = 'PROMO_LOAD_FINISH';

export const PROMO_DELETE = "PROMO_DELETE";
export const PROMO_DELETED = "PROMO_DELETED";
export const PROMO_ADD = "PROMO_ADD";
export const PROMO_ADDED = "PROMO_ADDED";

export const promoLoad = (token) => {
    return {
        type: PROMO_LOAD,
        token: token
    }
};

export const promoLoadFinish = (data) => {
    return {
        type: PROMO_LOAD_FINISH,
        data: data
    }
};

export const promoDelete = (token, data) => {
    return {
        type: PROMO_DELETE,
        data: data,
        token: token
    }
};

export const promoDeleted = (id) => {
    return {
        type: PROMO_DELETED,
        id: id,
    }
};

export const promoAdd = (token, payload) => {
    return {
        type: PROMO_ADD,
        token: token,
        payload: payload
    }
};

export const promoAdded = (data) => {
    return {
        type: PROMO_ADDED,
        data: data
    }
}
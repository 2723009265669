import {put} from "@redux-saga/core/effects";
import * as actions from "./banActions";
import * as systemActions from "../system/systemActions";
import axios from "axios";

export function* banLoadSaga(action) {
    let config = {
        headers: {
            Authorization: "Bearer " + action.token,
            Accept: "application/json"
        }
    };

    try {
        const response = yield axios.get(
            "/v1/ban/company",
            config
        );

        yield put(actions.banLoaded(response.data.result));

    } catch (error) {

    }
}

export function* banRemoveSaga(action) {
    let config = {
        headers: {
            Authorization: "Bearer " + action.token,
            Accept: "application/json"
        }
    };

    try {
        const response = yield axios.get(
            "/v1/ban/company/remove/" + action.payload,
            config
        );
        yield put(actions.banLoad(action.token));

    } catch (error) {

    }
}

export function* banAddSaga(action) {

    let config = {
        headers: {
            Authorization: "Bearer " + action.token,
            Accept: "application/json"
        }
    };

    try {
        const response = yield axios.post(
            "/v1/ban/company/add",
            {company: action.payload},
            config
        );

        yield put(actions.banLoad(action.token));

    } catch (error) {

    }
}
import React from "react";

import { Drawer } from "antd";
import { RiCloseFill } from "react-icons/ri";
import { Link } from "react-router-dom";
import MenuItem from "../item";
import MenuFooter from "../footer";
import logoSmall from "../../../../assets/images/logo/logo-small.svg";

export default function MenuMobile(props) {
    const { onClose, visible } = props;

    return (
        <Drawer
            title={
                <Link
                    to="/"
                    onClick={onClose}
                >
                    <img className="hp-logo" src={logoSmall} alt="logo" />
                </Link>
            }
            className="hp-mobile-sidebar"
            placement="left"
            closable={true}
            onClose={onClose}
            visible={visible}
            closeIcon={
                <RiCloseFill
                    className="remix-icon hp-text-color-black-0"
                    size={24}
                />
            }
        >
            <MenuItem onClose={onClose} />

            <MenuFooter onClose={onClose} collapsed={false} />
        </Drawer>
    );
};
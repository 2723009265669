import * as actionTypes from "./logsActions";
import { updateObject } from "../../shared/utility";


const initialState = {
    clients: null,
    companies: null,
    logs: null,
    filter: null,
    offset: null,
    logsLoading: false,
    totals: null,
    daily: null,
    platforms: null,
    requestStatistic: null,
    topRequestStatistic: null
};


const getClientListDone = (state, action) => {
    return updateObject(
        state,
        {
            ...action.payload,
            companies: null,
            logs: null
        }
    );
};

const getClientCompaniesDone = (state, action) => {
    return updateObject(
        state,
        {
            ...action.payload,
            logs: null
        }
    );
};

const clearClientCompnies = (state, action) => {
    return updateObject(
        state,
        {companies: null, logs: null}
    );
};

const getCompanyLogsLoading = (state, action) => {
    return updateObject(state, {logsLoading: true});
};

const getCompanyLogsDone = (state, action) => {
    return updateObject(state,{
        logs: action.payload,
        logsLoading: false,
    })
};

const getUserCompanyFilterDone = (state, action) => {

    let filter = null;
    try {
        const company = action.payload.company;
        const cfil = action.payload.filters;
        filter = {...state.filter};
        filter[company] = cfil;

    } catch (e) {
        console.log("FAILED: ", e);
    }

    return updateObject(state, {
        filter: filter
    })
};

const clearUserCompanyFilters = (state, action) => {
    return updateObject(state, {filter: null});
};

const loadCompanyTotalsDone = (state, action) => {
    return updateObject(state, {totals: action.payload});
};

const loadCompanyDailyDone = (state, action) => {
    return updateObject(state, {daily: action.payload});
};

const loadCompanyPlatformsDone = (state, action) => {
    return updateObject(state, {platforms: action.payload});
};

const loadRequestStatisticDone = (state, action) => {
    return updateObject(state, {requestStatistic: action.payload});
};

const loadTopRequestStatisticDone = (state, action) => {
    return updateObject(state, {topRequestStatistic: action.payload});
};

const reducer = ( state = initialState, action ) => {
    switch ( action.type ) {
        case actionTypes.GET_CLIENT_LIST_DONE: return getClientListDone(state, action);
        case actionTypes.GET_CLIENT_COMPANIES_DONE: return getClientCompaniesDone(state, action);
        case actionTypes.CLEAR_CLIENT_COMPANIES: return clearClientCompnies(state, action);
        case actionTypes.GET_COMPANY_LOGS_DONE: return getCompanyLogsDone(state, action);
        case actionTypes.GET_COMPANY_LOGS_LOADING: return  getCompanyLogsLoading(state, action);
        case actionTypes.GET_USER_COMPANY_FILTER_DONE: return getUserCompanyFilterDone(state, action);
        case actionTypes.CLEAR_USER_COMPANY_FILTERS: return clearUserCompanyFilters(state, action);
        case actionTypes.GET_COMPANY_TOTALS_DONE: return loadCompanyTotalsDone(state, action);
        case actionTypes.GET_COMPANY_DAILY_DONE: return loadCompanyDailyDone(state, action);
        case actionTypes.GET_COMPANY_PLATFORM_DONE: return loadCompanyPlatformsDone(state, action);
        case actionTypes.GET_REQUEST_STATISTIC_DONE: return loadRequestStatisticDone(state, action);
        case actionTypes.GET_TOP_REQUEST_STATISTIC_DONE: return loadTopRequestStatisticDone(state, action);
        default:
            return state;
    }
};

export default reducer;
import {put} from "@redux-saga/core/effects";
import * as actions from "./conversionActions";
import axios from "axios";

export function* loadConversionSaga(action) {
    let config = {
        headers: {
            Authorization: "Bearer " + action.token,
            Accept: "application/json"
        }
    };

    try {
        const response = yield axios.get(
            "/v1/conversion/filter",
            config
        );
        yield put(actions.conversionLoadFinish(response.data.result));

    } catch (error) {

    }
};

export function* loadConversionOnFilterChange(action) {

    let config = {
        headers: {
            Authorization: "Bearer " + action.token,
            Accept: "application/json"
        }
    }
    try {
        const response = yield axios.post(
            "/v1/conversion/stats",
            action.values,
            config
        );

        if (response.data && response.data.result && response.status == 200) {
            yield put(actions.conversionFilterChangeFinish(response.data.result));
        }

    } catch (error) {

    }
};

export function* loadConversionFieldsSaga(action) {
    let config = {
        headers: {
            Authorization: "Bearer " + action.token,
            Accept: "application/json"
        }
    };

    try {
        const response = yield axios.get(
            "/v1/conversion/fields",
            config
        );
        yield put(actions.conversionFieldsLoadFinish(response.data.result));

    } catch (error) {

    }
};


export function* loadConversionOnFieldsChange(action) {

    let config = {
        headers: {
            Authorization: "Bearer " + action.token,
            Accept: "application/json"
        }
    }
    try {
        const response = yield axios.post(
            "/v1/conversion/fields",
            action.data,
            config
        );

    } catch (error) {

    }
};
export const INTEGRATION_LOAD = 'INTEGRATION_LOAD';
export const INTEGRATION_LOAD_FINISH = 'INTEGRATION_LOAD_FINISH';

export const INTEGRATION_EDIT = "INTEGRATION_EDIT";
export const INTEGRATION_EDITED = "INTEGRATION_EDITED";


export const integrationLoad = (token, language) => {
    return {
        type: INTEGRATION_LOAD,
        token: token,
        language: language
    }
};

export const integrationLoadFinish = (data) => {
    return {
        type: INTEGRATION_LOAD_FINISH,
        data: data
    }
};

export const integrationEdit = (token, language, category, payload) => {
    return {
        type: INTEGRATION_EDIT,
        token: token,
        language: language,
        category: category,
        payload: payload
    }
};

export const integrationEdited = (data) => {
    return {
        type: INTEGRATION_EDITED,
        data: data
    }
};
import React from "react";

import { useSelector } from "react-redux";

import { Row, Col } from "antd";

import logoSvg from "../../../assets/images/logo/logo.svg";

import {withTranslation} from "react-i18next";


function LeftContent(props) {
  // Redux
  const theme = useSelector(state => state.customise.theme);

  const  { t } = props;

  return (
    <Col lg={12} span={24} className="hp-bg-color-primary-4 hp-bg-color-dark-90 hp-position-relative">
      <Row className="hp-image-row hp-h-100 hp-px-sm-8 hp-px-md-16 hp-pb-sm-32 hp-pt-md-96 hp-pt-md-32" align="middle" justify="center">


        <Col span={24}>
          <Row align="middle" justify="center" className="hp-h-100" gutter={[10,88]}>
            <Col md={20} span={24} className="hp-bg-item hp-text-center hp-mb-md-32">
              <img src={logoSvg} alt="Background Image" />
            </Col>

            <Col xl={18} span={24} className="hp-text-item hp-text-center">
              <h2 className="hp-mx-lg-16 hp-mb-16"  style={{"color": "#457f9c"}}>
                {t("Welcome to Palladium")}
              </h2>
              <p className="hp-mb-0 hp-text-color-black-80 hp-text-color-dark-30">
                {t("Welcome Description")}
              </p>
            </Col>
          </Row>
        </Col>
      </Row>
    </Col>
  );
};
export default withTranslation()(LeftContent);
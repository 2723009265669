import { Link, Redirect } from "react-router-dom";

import { connect } from 'react-redux';

import { Row, Col, Form, Input, Button, Alert } from "antd";

import LeftContent from "../leftContent";

import * as actions from "../../../../redux/auth/authActions";
import React from "react";
import HeaderLanguge from "../../../../layout/components/header/HeaderLanguage";
import {withTranslation} from "react-i18next";

const RecoverPassword = (props) => {
    let description = '';

    const {t} = props;

    switch (props.recoverStatus) {
        case 'success':
            description = t('We have emailed your password reset link!');
            break;
        case 'error':
            description = t('We can\'t find a user with that email address.');
            break;
    }

    let alert = null;
    if (props.recoverStatus) {
        alert = <Alert
            className="hp-mt-16"
            message={t("Recover Password")}
            description={t(description)}
            type={props.recoverStatus}
            closable
        />
    }

    return (
        <Row gutter={[32, 0]} className="hp-authentication-page" style={{height:"100vh"}}>
            <LeftContent />
            <Col lg={12} span={24} className="hp-py-sm-0 hp-py-md-64">
                <Row>
                    <Col offset={20} span={4} align="right">
                        <Row style={{"paddingTop": "20px"}}>
                            <HeaderLanguge lang="en" />
                        </Row>
                    </Col>
                </Row>
                <Row className="hp-h-100" align="middle" justify="center" gutter={[32, {xxl: 11, xl:15, lg: 20, md: 20, sm: 24}]}>
                <Col
                    span={20}
                    className="hp-px-sm-8 hp-pt-24 hp-pb-48"
                >
                    <h1 className="hp-mb-sm-0">{t("Recover Password")}</h1>
                    <p className="hp-mt-sm-0 hp-mt-8 hp-text-color-black-60">
                        {t("Email a password")}
                    </p>
                    {alert}
                    <Form
                        layout="vertical"
                        name="basic"
                        className="hp-mt-sm-16 hp-mt-32"
                        initialValues={{remember: true}}
                        onFinish={props.onRecoverPassword}
                        >
                        <Form.Item label={t("Email")} name="email">
                            <Input id="validating" placeholder="you@example.com"/>
                        </Form.Item>

                        <Form.Item className="hp-mt-16 hp-mb-8">
                            <Button block type="primary" htmlType="submit">
                                {t("Recover Password")}
                            </Button>
                        </Form.Item>
                    </Form>

                    <div className="hp-form-info">
                        <span className="hp-text-color-black-80 hp-text-color-dark-40 hp-caption hp-mr-4">
                            {t("Go back to")}
                        </span>

                        <Link
                            to="/authentication/login"
                            className="hp-text-color-primary-1 hp-text-color-dark-primary-2 hp-caption"
                        >
                            {t("Login Page")}
                        </Link>
                    </div>
                </Col>
                </Row>
            </Col>
        </Row>
    );
};

const mapStateToProps = state => {
    return {
        recoverStatus: state.auth.recoverStatus,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onRecoverPassword: (values) => dispatch( actions.recoverPassword(values) )
    };
};

export default connect( mapStateToProps, mapDispatchToProps )( withTranslation()(RecoverPassword));

import {put} from "@redux-saga/core/effects";
import * as actions from "./promoActions";
import * as systemActions from "../system/systemActions";
import axios from "axios";

export function* loadPromoSaga(action) {

    let config = {
        headers: {
            Authorization: "Bearer " + action.token,
            Accept: "application/json"
        }
    }

    try {
        const response = yield axios.get(
            "/v1/promo",
            config
        );
        yield put(actions.promoLoadFinish(response.data.result));

    } catch (error) {

    }
};

export function* deletePromoSaga(action) {
    let config = {
        headers: {
            Authorization: "Bearer " + action.token,
            Accept: "application/json"
        }
    }

    try {
        const response = yield axios.delete(
            "/v1/promo/" + action.data,
            config
        );

        if (response.data.result && response.data.result.success) {
            yield put(actions.promoLoad(action.token));
            yield put(systemActions.systemNotification(response.data.result.message));
        } else {
            yield put(systemActions.axiosError(response.data.result));
        }

    } catch (error) {

    }
}

export function* addPromo(action) {

    let config = {
        headers: {
            Authorization: "Bearer " + action.token,
            Accept: "application/json"
        }
    }

    let response = null;

    try {

        if (!action.payload.id) {
            response = yield axios.post(
                "/v1/promo",
                action.payload,
                config
            );
        } else {
            response = yield axios.put(
                "/v1/promo/" + action.payload.id,
                action.payload,
                config
            );
        }

        if (response.data.result && response.data.result.success) {
            yield put(actions.promoLoad(action.token));
            yield put(systemActions.systemNotification(response.data.result.message));
        } else {
            yield put(systemActions.axiosError(response.data.result));
        }

    } catch (error) {
        console.log("Error:", error)
    }
}
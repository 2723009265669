export const REFERRAL_LOAD = 'REFERRAL_LOAD';
export const REFERRAL_LOAD_FINISH = 'REFERRAL_LOAD_FINISH';

export const ROYALTY_ADD = "ROYALTY_ADD";

export const referralLoad = (token, start, end) => {
    return {
        type: REFERRAL_LOAD,
        token: token,
        start: start,
        end: end
    }
}

export const referralLoadFinish = (payload) => {
    return {
        type: REFERRAL_LOAD_FINISH,
        payload: payload
    }
};

export const royaltyAdd = (token, payload) => {
    return {
        type: ROYALTY_ADD,
        token: token,
        payload: payload
    }
};
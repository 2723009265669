import {updateObject} from "../../shared/utility";
import * as actionTypes from "./teamUsersActions";

const initialState = {
    teamUsers: null,
    errorMessage: null,
    addUserResponse: null,
    updateUserResponse: null,
    deleteUserResponse: null,
};

const teamUsersLoadFinished = (state, action) => {
    return updateObject(
        state,
        action.payload
    );
};

const teamUserAddSuccess = (state, action) => {
    return updateObject(
        state,
        {
            addUserResponse: 'success',
            teamUsers: action.payload.teamUsers,
            errorMessage: null,
        }
    );
}

const teamUserAddFail = (state, action) => {
    return updateObject(
        state,
        {
            addUserResponse: 'fail',
            errorMessage: action.payload,
        }
    );
}

const teamUserUpdateSuccess = (state, action) => {
    return updateObject(
        state,
        {
            updateUserResponse: 'success',
            teamUsers: action.payload.teamUsers,
            errorMessage: null,
        }
    );
}

const teamUserUpdateFail = (state, action) => {
    return updateObject(
        state,
        {
            updateUserResponse: 'fail',
            errorMessage: action.payload,
        }
    );
}

const teamUserDeleteSuccess = (state, action) => {
    return updateObject(
        state,
        {
            deleteUserResponse: 'success',
            teamUsers: action.payload.teamUsers,
            errorMessage: null,
        }
    );
}

const teamUserDeleteFail = (state, action) => {
    return updateObject(
        state,
        {
            deleteUserResponse: 'fail',
            errorMessage: action.payload,
        }
    );
}

const reducer = ( state = initialState, action ) => {
    switch ( action.type ) {
        case actionTypes.TEAM_USERS_LOAD_FINISH: return teamUsersLoadFinished(state, action);
        case actionTypes.TEAM_USER_ADD_FAILED: return teamUserAddFail(state, action);
        case actionTypes.TEAM_USER_ADD_SUCCESS: return teamUserAddSuccess(state, action);
        case actionTypes.TEAM_USER_UPDATE_FAILED: return teamUserUpdateFail(state, action);
        case actionTypes.TEAM_USER_UPDATE_SUCCESS: return teamUserUpdateSuccess(state, action);
        case actionTypes.TEAM_USER_DELETE_FAILED: return teamUserDeleteFail(state, action);
        case actionTypes.TEAM_USER_DELETE_SUCCESS: return teamUserDeleteSuccess(state, action);
        default:
            return state;
    }
};

export default reducer;
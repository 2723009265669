export const GET_NOTIFICATIONS = "GET_NOTIFICATIONS";
export const GET_NOTIFICATIONS_DONE = "GET_NOTIFICATIONS_DONE";

export const CLEAR_NOTIFICATIONS = "CLEAR_NOTIFICATIONS";
export const CLEAR_NOTIFICATIONS_DONE = "CLEAR_NOTIFICATIONS_DONE";

export const ADD_NOTIFICATION = "ADD_NOTIFICATION";

export const getNotifications = (token) => {
    return {
        type: GET_NOTIFICATIONS,
        token: token,
    }
};

export const getNotificationsDone = (payload) => {
    return {
        type: GET_NOTIFICATIONS_DONE,
        payload: payload,
    }
};

export const addNotification = (token, payload) => {
    return {
        type: ADD_NOTIFICATION,
        token: token,
        payload: payload
    }
}

export const clearNotifications = (token) => {
    return {
        type: CLEAR_NOTIFICATIONS,
        token: token,
    }
};

export const clearNotificationsDone = () => {
    return {
        type: CLEAR_NOTIFICATIONS_DONE,
    }
};
import {put} from "@redux-saga/core/effects";
import * as actions from "./clientActions";
import * as systemActions from "../system/systemActions";
import axios from "axios";

export function* clientsLoadSaga(action){
    let config = {
        headers: {
            Authorization: "Bearer " + action.token,
            Accept: "application/json"
        }
    };

    try {
        const response = yield axios.post(
            "/v1/clients",
            action.payload,
            config
        );
        yield put(actions.clientsLoadFinish(response.data.result));

    } catch (error) {

    }
}

export function* clientsSetActivation(action){
    let config = {
        headers: {
            Authorization: "Bearer " + action.token,
            Accept: "application/json"
        }
    };

    try {
        const response = yield axios.get(
            "/v1/clients/activate?id=" + action.payload.record + "&status=" + action.payload.activation,
            config
        );
        if (response.data.result.success) {
            yield put(actions.clientsSetActivationDone({
                index: action.payload.index,
                activation: !action.payload.activation,
                record: action.payload.record
            }));
        }

    } catch (error) {

    }
}

export function* clientAddPayment(action) {
    let config = {
        headers: {
            Authorization: "Bearer " + action.token,
            Accept: "application/json"
        }
    };

    try {
        const response = yield axios.post(
            "/v1/clients/payment",
            action.payload,
            config
        );

        if (response.data.result.success) {
            yield put(systemActions.systemNotification(response.data.result.message));
        }

    } catch (error) {

    }
}

export function* clientAddNotes(action) {
    let config = {
        headers: {
            Authorization: "Bearer " + action.token,
            Accept: "application/json"
        }
    };

    try {
        const response = yield axios.post(
            "/v1/clients/notes",
            action.payload,
            config
        );

        if (response.data.result.success) {
            yield put(systemActions.systemNotification(response.data.result.message));
            yield put(actions.clientsLoad(action.token));
        }

    } catch (error) {

    }
}

export function* clientUpdateEmail(action) {
    let config = {
        headers: {
            Authorization: "Bearer " + action.token,
            Accept: "application/json"
        }
    };

    try {
        const response = yield axios.post(
            "/v1/clients/updateEmail",
            action.payload,
            config
        );

        if (response.data.result.success) {
            yield put(actions.clientsUpdateEmailSuccess(response.data.result));
            yield put(actions.clientsLoad(action.token));
        } else {
            yield put(actions.clientsUpdateEmailFail(response.data.result.message.errors));
        }

    } catch (error) {

    }
}
import { combineReducers } from "redux";

import customiseReducer from "./customise/customiseReducer";
import authReducer from "../redux/auth/authReducer";
import systemReducer from "../redux/system/systemReducer";
import profileReducer from "../redux/profile/profileReducer";
import promoReducer from "../redux/promo/promoReducer";
import referralReducer from "../redux/referral/referralReducer";
import paymentReducer from "../redux/payments/paymentReducer";
import tariffReducer from "../redux/tariff/tariffReducer";
import clientReducer from "../redux/clients/clientReducer";
import referralClientsReducer from "../redux/referralClients/referralClientsReducer";
import blackListReducer from "../redux/blacklist/blackListReducer";
import clientBlackListReducer from "../redux/clientBlacklist/clientBlackListReducer";
import integrationReducer from "../redux/integration/integrationReducer";
import logsReducer from "../redux/logs/logsReducer";
import campaignReducer from "../redux/campaign/campaignReducer";
import notificationReducer from "../redux/notifications/notificationsReducer";
import teamUsersReducer from "../redux/teamUsers/teamUsersReducer";
import apiReducer from "../redux/api/apiReducer";
import conversionReducer from "../redux/conversion/conversionReducer";
import expirationsReducer from "../redux/expirations/expirationsReducer";
import banReducer from "../redux/ban/banReducer";
import managersReducer from "../redux/managers/managersReducer";

const rootReducer = combineReducers({
  customise: customiseReducer,
  auth: authReducer,
  system: systemReducer,
  profile: profileReducer,
  promo: promoReducer,
  referrals: referralReducer,
  payments: paymentReducer,
  tariff: tariffReducer,
  clients: clientReducer,
  referralClients: referralClientsReducer,
  blackList: blackListReducer,
  clientBlackList: clientBlackListReducer,
  integration: integrationReducer,
  logs: logsReducer,
  campaign: campaignReducer,
  notifications: notificationReducer,
  teamUsers: teamUsersReducer,
  api:apiReducer,
  conversion: conversionReducer,
  expirations: expirationsReducer,
  ban: banReducer,
  managers: managersReducer,
});

export default rootReducer;
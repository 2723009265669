import * as actionTypes from "./conversionActions";
import { updateObject } from "../../shared/utility";


const initialState = {
    conversionFilter: null,
    conversionFields: null,
};

const conversionLoadFinished = (state, action) => {
    return updateObject(
        state,
        {conversionFilter: action.data}
    );
};

const conversionFilterChangeFinish = (state, action) => {

    return updateObject(
        state,
        {stats: action.data}
    );
}

const conversionFieldsLoadFinished = (state, action) => {
    return updateObject(
        state,
        {conversionFields: action.data}
    );
}

const reducer = ( state = initialState, action ) => {
    switch ( action.type ) {
        case actionTypes.CONVERSION_LOAD_FINISH: return conversionLoadFinished(state, action);
        case actionTypes.CONVERSION_FILTER_CHANGE_FINISH: return conversionFilterChangeFinish(state, action);
        case actionTypes.CONVERSION_FIELDS_LOAD_FINISH: return conversionFieldsLoadFinished(state, action);
        default:
            return state;
    }
};

export default reducer;
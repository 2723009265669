export const TEAM_USERS_LOAD = 'TEAM_USERS_LOAD';
export const TEAM_USERS_LOAD_FINISH = 'TEAM_USERS_LOAD_FINISH';

export const TEAM_USER_ADD = "TEAM_USER_ADD";
export const TEAM_USER_ADD_FAILED = "TEAM_USER_ADD_FAILED";
export const TEAM_USER_ADD_SUCCESS = "TEAM_USER_ADD_SUCCESS";

export const TEAM_USER_UPDATE = "TEAM_USER_UPDATE";
export const TEAM_USER_UPDATE_FAILED = "TEAM_USER_UPDATE_FAILED";
export const TEAM_USER_UPDATE_SUCCESS = "TEAM_USER_UPDATE_SUCCESS";

export const TEAM_USER_DELETE = "TEAM_USER_DELETE";
export const TEAM_USER_DELETE_FAILED = "TEAM_USER_DELETE_FAILED";
export const TEAM_USER_DELETE_SUCCESS = "TEAM_USER_DELETE_SUCCESS";

export const teamUsersLoad = (token) => {
    return {
        type: TEAM_USERS_LOAD,
        token: token,
    }
}

export const teamUsersLoadFinish = (payload) => {
    return {
        type: TEAM_USERS_LOAD_FINISH,
        payload: payload
    }
};

export const teamUserAdd = (token, payload) => {
    return {
        type: TEAM_USER_ADD,
        token: token,
        payload: payload
    }
};

export const teamUserAddFail = (error) => {
    return {
        type: TEAM_USER_ADD_FAILED,
        payload: error
    }
};

export const teamUserAddSuccess = (payload) => {
    return {
        type: TEAM_USER_ADD_SUCCESS,
        payload: payload
    }
};

export const teamUserUpdate = (token, payload) => {
    return {
        type: TEAM_USER_UPDATE,
        token: token,
        payload: payload
    }
};

export const teamUserUpdateSuccess= (payload) => {
    return {
        type: TEAM_USER_UPDATE_SUCCESS,
        payload: payload
    }
};

export const teamUserUpdateFail = (error) => {
    return {
        type: TEAM_USER_UPDATE_FAILED,
        payload: error
    }
};

export const teamUserDelete = (token, payload) => {
    return {
        type: TEAM_USER_DELETE,
        token: token,
        payload: payload
    }
};

export const teamUserDeleteSuccess = (payload) => {
    return {
        type: TEAM_USER_DELETE_SUCCESS,
        payload: payload
    }
};

export const teamUserDeleteFail = (error) => {
    return {
        type: TEAM_USER_DELETE_FAILED,
        payload: error
    }
};
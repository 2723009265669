import * as actionTypes from "./integrationActions";
import { updateObject } from "../../shared/utility";


const initialState = {
    tabNames: null,
    tabContent: null
};

const integrationLoadFinished = (state, action) => {
    return updateObject(
        state,
        action.data
    );
};

const integrationEdited = (state, action) => {
    let content = action.data.payload.content;
    let index = action.data.payload.index;

    let tabContent = [...state.tabContent];
    tabContent[index] = updateObject(
        state.tabContent[index],
        {content: content}
    );

    return updateObject(state, {tabContent: tabContent});
};

const reducer = ( state = initialState, action ) => {
    switch ( action.type ) {
        case actionTypes.INTEGRATION_LOAD_FINISH: return integrationLoadFinished(state, action);
        case actionTypes.INTEGRATION_EDITED: return integrationEdited(state, action);
        default:
            return state;
    }
};

export default reducer;
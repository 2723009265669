export const CLIENTS_LOAD = 'CLIENTS_LOAD';
export const CLIENTS_LOAD_FINISH = 'CLIENTS_LOAD_FINISH';

export const CLIENTS_SET_ACTIVATION = "CLIENTS_SET_ACTIVATION";
export const CLIENTS_SET_ACTIVATION_DONE = "CLIENTS_SET_ACTIVATION_DONE";

export const CLIENT_ADD_PAYMENT = "CLIENT_ADD_PAYMENT";

export const CLIENT_ADD_NOTES = "CLIENT_ADD_NOTES";

export const CLIENT_UPDATE_EMAIL = "CLIENT_UPDATE_EMAIL";
export const CLIENT_UPDATE_EMAIL_SUCCESS = "CLIENT_UPDATE_EMAIL_SUCCESS";
export const CLIENT_UPDATE_EMAIL_FAILED = "CLIENT_UPDATE_EMAIL_FAILED";

export const clientsLoad = (token, payload) => {
    return {
        type: CLIENTS_LOAD,
        token: token,
        payload: payload
    }
};

export const clientsLoadFinish = (payload) => {
    return {
        type: CLIENTS_LOAD_FINISH,
        payload: payload
    }
};

export const clientsSetActivation = (token, payload) => {
    return {
        type: CLIENTS_SET_ACTIVATION,
        token: token,
        payload: payload
    }
};

export const clientsSetActivationDone= (payload) => {
    return {
        type: CLIENTS_SET_ACTIVATION_DONE,
        payload: payload
    }
};

export const clientsAddPayment = (token, payload) => {
    return {
        type: CLIENT_ADD_PAYMENT,
        token: token,
        payload: payload
    }
};


export const clientsAddNotes = (token, payload) => {
    return {
        type: CLIENT_ADD_NOTES,
        token: token,
        payload: payload
    }
};

export const clientsUpdateEmail = (token, payload) => {
    return {
        type: CLIENT_UPDATE_EMAIL,
        token: token,
        payload: payload
    }
};

export const clientsUpdateEmailSuccess = (payload) => {
    return {
        type: CLIENT_UPDATE_EMAIL_SUCCESS,
        payload: payload
    }
};

export const clientsUpdateEmailFail = (error) => {
    return {
        type: CLIENT_UPDATE_EMAIL_FAILED,
        payload: error
    }
};

import React, { useState, useEffect } from "react";

import { useSelector } from "react-redux";

import { Layout, Row, Col } from "antd";

import Sidebar from "./components/menu/Sidebar";
import MenuHeader from "./components/header";
import MenuFooter from "./components/footer";
import ScrollTop from "./components/scroll-to-top";

import {connect} from "react-redux";
import * as profileActions from "./../redux/profile/profileActions";
import {withRouter} from "react-router-dom";
import { useLocation } from "react-router-dom";

const { Content } = Layout;

const VerticalLayout = (props) => {
    const { children } = props;

    const [visible, setVisible] = useState(false);

    // Redux
    const auth = useSelector(state => state.auth);
    const customise = useSelector(state => state.customise);

    const location = useLocation();
    useEffect(() => {
        if (auth.is_admin === false && location.pathname !== "/authentication/login") {
            props.onProfileLoad(auth.token);
        }
    }, [location]);
    
    return (
        <Layout className="hp-app-layout">
            <Sidebar visible={visible} setVisible={setVisible} />

            <Layout className="hp-bg-color-dark-90">
                <MenuHeader setVisible={setVisible} />

                <Content className="hp-content-main">
                    <Row justify="center">
                        {
                            customise.contentWidth == "full" && (
                                <Col span={24}>
                                    {children}
                                </Col>
                            )
                        }

                        {
                            customise.contentWidth == "boxed" && (
                                <Col xxl={20} xl={22} span={24}>
                                    {children}
                                </Col>
                            )
                        }
                    </Row>
                </Content>

                <MenuFooter />
            </Layout>

            <ScrollTop />
        </Layout>
    );
};

const mapStateToProps = state => {
    return {
        profile: state.profile.profile
    }
};

const mapDispatchToProps = dispatch => {
    return {
        onProfileLoad: (token) => dispatch(profileActions.profileLoad(token)),
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(VerticalLayout));
export const EXPIRATIONS_LOAD = 'EXPIRATIONS_LOAD';
export const EXPIRATIONS_LOAD_FINISH = 'EXPIRATIONS_LOAD_FINISH';

export const expirationsLoad = (token) => {
    return {
        type: EXPIRATIONS_LOAD,
        token: token
    }
};

export const expirationsLoadFinish = (data) => {
    return {
        type: EXPIRATIONS_LOAD_FINISH,
        data: data
    }
};
export const TARIFF_LOAD = 'TARIFF_LOAD';
export const TARIFF_LOAD_FINISH = 'TARIFF_LOAD_FINISH';

export const TARIFF_ADD = "TARIFF_ADD";
export const TARIFF_DELETE = "TARIFF_DELETE";

export const TARIFF_CHANGE = "TARIFF_CHANGE";

export const tariffLoad = (token) => {
    return {
        type: TARIFF_LOAD,
        token: token,
    }
}

export const tariffLoadFinish = (payload) => {
    return {
        type: TARIFF_LOAD_FINISH,
        payload: payload
    }
};

export const tariffAdd = (token, payload) => {
    return {
        type: TARIFF_ADD,
        token: token,
        payload: payload
    }
};

export const tariffDelete = (token, payload) => {
    return {
        type: TARIFF_DELETE,
        token: token,
        payload: payload
    }
};

export const tariffChange = (token, payload) => {
    return {
        type: TARIFF_CHANGE,
        token: token,
        payload: payload
    }
};

import {put} from "@redux-saga/core/effects";
import * as actions from "./apiActions";
import axios from "axios";

export function* apiKeyLoadSaga(action) {
    let config = {
        headers: {
            Authorization: "Bearer " + action.token,
            Accept: "application/json"
        }
    };

    try {
        const response = yield axios.get(
            "/v1/apiKey/getKey",
            config
        );
        yield put(actions.apiKeyLoadFinish(response.data.result));

    } catch (error) {

    }
}

export function* apiSwaggerConfigLoadSaga(action) {
    let config = {
        headers: {
            Authorization: "Bearer " + action.token,
            Accept: "application/json"
        }
    };
    try {
        const response = yield axios.get(
            "/v1/apiKey/getSwaggerConfig",
            config
        );

        yield put(actions.apiSwaggerConfigLoadFinish(response.data.result));

    } catch (error) {

    }
}

export function* apiKeyGenerateSaga(action) {

    let config = {
        headers: {
            Authorization: "Bearer " + action.token,
            Accept: "application/json"
        }
    }

    try {
        const response = yield axios.get(
            "/v1/apiKey/generateKey",
            config
        );
        if (response.data.result.success) {
            yield put(actions.apiKeyGenerateSuccess(response.data.result));
        } else {
            yield put(actions.apiKeyGenerateFail(response.data.result.message.errors));
        }
    } catch (error) {

    }
}

export function* apiKeyDeleteSaga(action) {

    let config = {
        headers: {
            Authorization: "Bearer " + action.token,
            Accept: "application/json"
        }
    }

    try {
        const response = yield axios.post(
            "/v1/apiKey/deleteKey",
            action.payload,
            config
        );
        if (response.data.result.success) {
            yield put(actions.apiKeyDeleteSuccess(response.data.result));
        } else {
            yield put(actions.apiKeyDeleteFail(response.data.result.message.errors));
        }
    } catch (error) {

    }
}
import {updateObject} from "../../shared/utility";
import * as actionTypes from "./banActions";

const initialState = {
    companies: []
};

const banLoaded = (state, action) => {
    return updateObject(
        state,
        {'companies' : action.payload}
    );
};


const reducer = ( state = initialState, action ) => {
    switch ( action.type ) {
        case actionTypes.BAN_LOADED: return banLoaded(state, action);
        default:
            return state;
    }
};

export default reducer;
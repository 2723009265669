import {updateObject} from "../../shared/utility";
import * as actions from "./systemActions";

const initialState = {
    requestLoading: false,
    requestError: null,
    notificationShow: false,
    notificationMessage: null,
};

const requestStart = ( state, action ) => {
    return updateObject( state, {
        requestLoading: true,
        requestError: null,
    } );
};

const requestEnd = ( state, action ) => {
    return updateObject( state, { requestLoading: false } );
};

const requestError = (state, action) => {
    return updateObject(state, {requestLoading: false, requestError: action.data})
}

//TODO: change type of notification processed !
const systemNotification = (state, action) => {
    return updateObject(state, {notificationShow: true, notificationMessage: action.data})
}

const systemNotificationEnd = (state, action) => {
    return updateObject(state, {notificationShow: false, notificationMessage: null})
}

const reducer = ( state = initialState, action ) => {
    switch ( action.type ) {
        case actions.AXIOS_START: return requestStart( state, action );
        case actions.AXIOS_END: return requestEnd(state, action);
        case actions.AXIOS_ERROR: return requestError(state, action);

        case actions.SYSTEM_NOTIFICATION: return systemNotification(state, action);
        case actions.SYSTEM_NOTIFICATION_END: return systemNotificationEnd(state, action);
        default: return state;
    }
};

export default reducer;
import * as actionTypes from "./expirationsActions";
import { updateObject } from "../../shared/utility";


const initialState = {
    expirations: []
};

const expirationsLoadFinished = (state, action) => {
    return updateObject(
        state,
        {expirations: [...action.data]}
    );
};

const reducer = ( state = initialState, action ) => {
    switch ( action.type ) {
        case actionTypes.EXPIRATIONS_LOAD_FINISH: return expirationsLoadFinished(state, action);
        default:
            return state;
    }
};

export default reducer;
import {updateObject} from "../../shared/utility";
import * as actionTypes from "./apiActions";

const initialState = {
    apiKey: null,
    errorMessage: null,
    generateKeyResponse: null,
    deleteKeyResponse: null,
};

const apiKeyLoadFinished = (state, action) => {
    return updateObject(
        state,
        action.payload
    );
};

const apiSwaggerConfigLoadFinished = (state, action) => {
    return updateObject(
        state,
        action.payload
    );
};

const apiKeyGenerateSuccess = (state, action) => {
    return updateObject(
        state,
        {
            generateKeyResponse: 'success',
            apiKey: action.payload.apiKey,
            errorMessage: null,
        }
    );
}

const apiKeyGenerateFail = (state, action) => {
    return updateObject(
        state,
        {
            generateKeyResponse: 'fail',
            errorMessage: action.payload,
        }
    );
}

const apiKeyDeleteSuccess = (state, action) => {
    return updateObject(
        state,
        {
            deleteKeyResponse: 'success',
            errorMessage: null,
        }
    );
}

const apiKeyDeleteFail = (state, action) => {
    return updateObject(
        state,
        {
            deleteKeyResponse: 'fail',
            errorMessage: action.payload,
        }
    );
}

const reducer = ( state = initialState, action ) => {
    switch ( action.type ) {
        case actionTypes.API_KEY_LOAD_FINISH: return apiKeyLoadFinished(state, action);
        case actionTypes.API_SWAGGER_CONFIG_LOAD_FINISH: return apiSwaggerConfigLoadFinished(state, action);
        case actionTypes.API_KEY_GENERATE_FAILED: return apiKeyGenerateFail(state, action);
        case actionTypes.API_KEY_GENERATE_SUCCESS: return apiKeyGenerateSuccess(state, action);
        case actionTypes.API_KEY_DELETE_FAILED: return apiKeyDeleteFail(state, action);
        case actionTypes.API_KEY_DELETE_SUCCESS: return apiKeyDeleteSuccess(state, action);
        default:
            return state;
    }
};

export default reducer;
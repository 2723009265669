import { Link, Redirect } from "react-router-dom";

import { connect } from 'react-redux';

import { Row, Col, Form, Input, Button, Alert } from "antd";

import LeftContent from "../leftContent";

import * as actions from "../../../../redux/auth/authActions";
import HeaderLanguge from "../../../../layout/components/header/HeaderLanguage";
import React from "react";
import {withTranslation} from "react-i18next";

const Register = (props) => {

    const {t} = props;

    let redirectPage = props.authRedirectPath;
    if (props.location.state && props.location.state.from) {
        redirectPage = props.location.state.from;
    }

    let authRedirect = null;
    if (props.token && props.token_type ) {
        authRedirect = <Redirect to={redirectPage} />
    }

    let alerts = null
    if (props.error) {
        let messages = props.errorMessage ? props.errorMessage : ["Uknown error"];
        alerts = renderAlerts(messages);
    }

    function renderAlerts(messages) {
        let alerts = messages.map((message, i) => {
            return (
                <Alert
                    key={i}
                    className="hp-mt-16"
                    message={t("Register Error")}
                    description={t(message)}
                    type="error"
                    closable
                />
            );
        });

        return alerts;
    }

    return (
        <Row gutter={[32, 0]} className="hp-authentication-page" style={{height:"100vh"}}>
            <LeftContent />
            {authRedirect}
            <Col lg={12} span={24}>
                <Row>
                    <Col offset={20} span={4} align="right">
                        <Row style={{"paddingTop": "20px"}}>
                            <HeaderLanguge lang="en" />
                        </Row>
                    </Col>
                </Row>
                <Row className="hp-h-100" align="middle" justify="center" gutter={[32, {xxl: 11, xl:15, lg: 20, md: 20, sm: 24}]}>
                    <Col
                        span={20}
                        className="hp-px-sm-8 hp-pt-24 hp-pb-48"
                        >
                        <h1>{t("Create New Account")}</h1>
                        <p className="hp-mt-8 hp-text-color-black-60">
                            {t("New Account Info")}
                        </p>
                        {alerts}
                        <Form
                            layout="vertical"
                            name="basic"
                            className="hp-mt-sm-16 hp-mt-32"
                            initialValues={{remember: true}}
                            onFinish={props.onRegister}
                        >
                            <Form.Item label={t("Email") + ": "} name="email">
                                <Input id="validating" />
                            </Form.Item>

                            <Form.Item label={t("Password")} name="password">
                                <Input.Password id="password"/>
                            </Form.Item>

                            <Form.Item label={t("Confirm Password")} name="password_confirmation">
                                <Input.Password id="confirm-password" />
                            </Form.Item>

                            <Form.Item label={t("Promo Code")} name="promo">
                                <Input id="promo-code" />
                            </Form.Item>

                            <Form.Item label={t("Telegram: ")} name="telegram">
                                <Input id="telegram" />
                            </Form.Item>

                            <Form.Item className="hp-mt-16 hp-mb-8">
                                <Button block type="primary" htmlType="submit">
                                    {t("Sign up")}
                                </Button>
                            </Form.Item>
                        </Form>

                        <div className="hp-form-info">
                            <span className="hp-text-color-black-80 hp-text-color-dark-40 hp-caption hp-mr-4">
                                {t("Already have an account")}
                            </span>

                            <Link
                                to="/authentication/login"
                                className="hp-text-color-primary-1 hp-text-color-dark-primary-2 hp-caption"
                                >
                                {t("Login")}
                            </Link>
                        </div>
                    </Col>
                </Row>
            </Col>
        </Row>
    );
};

const mapStateToProps = state => {
    return {
        token: state.auth.token,
        token_type: state.auth.token_type,
        error: state.auth.error,
        errorMessage: state.auth.errorMessage,
        authRedirectPath: "/"
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onRegister: (values) => dispatch( actions.register(values) )
    };
};


export default connect( mapStateToProps, mapDispatchToProps )( withTranslation()(Register) );
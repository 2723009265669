import * as actionTypes from "./notificationsActions";
import { updateObject } from "../../shared/utility";


const initialState = {
    notifications: null
};


const getNotificationsDone = (state, action) => {
    return updateObject(
        state,
        {
            ...action.payload,
        }
    );
};

const clearNotificationsDone = (state, action) => {
    return updateObject(
        state,
        {
            notifications: [],
        }
    );
}

const reducer = ( state = initialState, action ) => {
    switch ( action.type ) {
        case actionTypes.GET_NOTIFICATIONS_DONE: return getNotificationsDone(state, action);
        case actionTypes.CLEAR_NOTIFICATIONS_DONE: return clearNotificationsDone(state, action);
        default:
            return state;
    }
};

export default reducer;
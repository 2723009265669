import thunk from 'redux-thunk'
import createDebounce from 'redux-debounced'
import rootReducer from './rootReducer'
import { createStore, applyMiddleware, compose } from 'redux'
import createSagaMiddleware from "redux-saga";

import * as sagas from "./indexSaga";

const sagaMiddleware = createSagaMiddleware();
const middleware = [thunk, createDebounce(), sagaMiddleware]
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

const store = createStore(rootReducer, {}, composeEnhancers(applyMiddleware(...middleware)))

sagaMiddleware.run(sagas.watchAuthSaga);
sagaMiddleware.run(sagas.watchProfileSaga);
sagaMiddleware.run(sagas.watchPromoSaga);
sagaMiddleware.run(sagas.watchReferralSaga);
sagaMiddleware.run(sagas.watchPaymentSaga);
sagaMiddleware.run(sagas.watchTariffSaga);
sagaMiddleware.run(sagas.watchClientSaga);
sagaMiddleware.run(sagas.watchReferralClientsSaga);
sagaMiddleware.run(sagas.watchBlackListSaga);
sagaMiddleware.run(sagas.watchClientBlackListSaga);
sagaMiddleware.run(sagas.watchIntegrationSaga);
sagaMiddleware.run(sagas.watchLogSaga);
sagaMiddleware.run(sagas.watchCampaignSaga);
sagaMiddleware.run(sagas.watchNotificationSaga);
sagaMiddleware.run(sagas.watchTeamUsersSaga);
sagaMiddleware.run(sagas.watchApiSaga);
sagaMiddleware.run(sagas.watchConversionSaga);
sagaMiddleware.run(sagas.watchExpirationsSaga);
sagaMiddleware.run(sagas.watchBanSaga);
sagaMiddleware.run(sagas.watchManagersSaga);

export default store
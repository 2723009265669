import {put, delay} from "@redux-saga/core/effects";
import * as actions from "./campaignActions";
import axios from "axios";
import * as systemActions from "../system/systemActions";

export function* campaignLoadSaga(action){
    let config = {
        headers: {
            Authorization: "Bearer " + action.token,
            Accept: "application/json"
        }
    };

    try {
        const response = yield axios.post(
            "/v1/company",
            action.payload,
            config
        );
        yield put(actions.campaignLoadDone(response.data.result));

    } catch (error) {

    }
}

export function* downloadWpIntegration(action) {
    let config = {
        headers: {
            Authorization: "Bearer " + action.token,
            Accept: "application/zip"
        },
        responseType: 'arraybuffer',
        encoding:null
    };

    try {
        const response = yield axios.get(
            "/v1/company/downloadWp?company=" + action.campaign,
            config
        );

        const url = window.URL.createObjectURL(new Blob([response.data],{type: "application/zip"}));
        const link = document.createElement('a');
        link.href = url;
        link.download = "palladium.zip";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);

    } catch (error) {
        console.log("ERROR", error);
    }
}

export function* downloadSite(action) {
    let config = {
        headers: {
            Authorization: "Bearer " + action.token,
            Accept: "application/json"
        },
    };

    try {
        const response = yield axios.post(
            "/v1/company/createSite",
            action.payload,
            config
        );

        let siteResponse = null;
        let statusResponse = null;
        if (response.data) {
            let isDone = 0;
            let num = 0;
            do {
                num++;
                statusResponse = yield axios.post(
                    "/v1/company/getSiteStatus",
                    {siteId: response.data},
                    config
                );
                yield put(actions.siteDownloadStatusUpdate(statusResponse.data));
                if (statusResponse.data == 100) {
                    isDone = true;
                    let downloadConfig = {
                        headers: {
                            Authorization: "Bearer " + action.token,
                            Accept: "application/zip"
                        },
                        responseType: 'arraybuffer',
                        encoding:null
                    }
                    action.payload.siteId = response.data;
                    siteResponse = yield axios.post(
                        "/v1/company/downloadSite",
                        action.payload,
                        downloadConfig
                    );
                }
                yield delay(5000);
            } while (!isDone)
        }

        if (siteResponse.data) { 
            const url = window.URL.createObjectURL(new Blob([siteResponse.data],{type: "application/zip"}));
            const link = document.createElement('a');
            link.href = url;
            link.download = action.payload.companyName + ".zip";
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
    } catch (error) {
        yield put(actions.siteDownloadStatusUpdate(500));
    }
}

export function* downloadIntegration(action) {
    let config = {
        headers: {
            Authorization: "Bearer " + action.token,
            Accept: "application/zip"
        },
        responseType: 'arraybuffer',
        encoding:null
    };

    try {
        const response = yield axios.get(
            "/v1/company/download?company=" + action.campaign,
            config
        );

        const url = window.URL.createObjectURL(new Blob([response.data],{type: "application/zip"}));
        const link = document.createElement('a');
        link.href = url;
        link.download = "integration.zip";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);

    } catch (error) {
        console.log("ERROR", error);
    }
}

export function* campaignEditData(action) {

    let config = {
        headers: {
            Authorization: "Bearer " + action.token,
            Accept: "application/json"
        }
    };

    try {
        const response = yield axios.get(
            "/v1/company/edit/" + action.campaign,
            config
        );
        yield put(actions.campaignEditDataDone(response.data.result));

    } catch (error) {
        console.log("Error:", error);
    }
}

export function* campaignSaveData(action) {
    let config = {
        headers: {
            Authorization: "Bearer " + action.token,
            Accept: "application/json"
        }
    };

    try {
        const response = yield axios.post(
            "/v1/campaign/saveCampaign",
            action.payload,
            config
        );

        if (response && response.data.result && response.data.result.success) {
            yield put(action.callback);
        } else if (response.data) {
            yield put(actions.campaignSaveFailed(response.data.errors));
        }

    } catch (error) {
        console.log("Error:", error);
    }
}

export function* campaignDelete(action) {
    let config = {
        headers: {
            Authorization: "Bearer " + action.token,
            Accept: "application/json"
        }
    };

    try {
        const response = yield axios.post(
            "/v1/company/delete/",
            action.payload,
            config
        );

        if (response.data.result.success) {
            yield put(actions.campaignLoad(action.token));
        }

    } catch (error) {
        console.log("Error:", error);
    }
}

export function* campaignCopy(action) {
    let config = {
        headers: {
            Authorization: "Bearer " + action.token,
            Accept: "application/json"
        }
    };

    try {
        const response = yield axios.get(
            "/v1/company/copy/" + action.campaignId,
            config
        );

        if (response.data.result.success) {
            action.history.push('/pages/campaign-page/change/' + response.data.result.id);
        }

    } catch (error) {
        console.log("Error:", error);
    }
}

export function* campaignUpdateInfoStatus(action) {
    let config = {
        headers: {
            Authorization: "Bearer " + action.token,
            Accept: "application/json"
        }
    };

    try {
        const response = yield axios.get(
            "/v1/company/updateInfoStatus/" + action.campaignId,
            config
        );

        if (response.data.result.success) {
            yield put(actions.campaignLoadDone(action.token));
        }

    } catch (error) {
        console.log("Error:", error);
    }
}

export function* campaignNameChange(action) {
    let config = {
        headers: {
            Authorization: "Bearer " + action.token,
            Accept: "application/json"
        }
    };

    try {
        const response = yield axios.post(
            "/v1/company/rename",
            action.payload,
            config
        );

        if (response.data.result.success) {
            yield put(actions.campaignNameChanged(action.payload.campaignId, action.payload.name));
        } else {
            yield put(systemActions.axiosError(response.data.result));
        }

    } catch (error) {
        console.log("Error:", error);
    }
}
import * as actionTypes from "./promoActions";
import { updateObject } from "../../shared/utility";

const initialState = {
    promos: null,
    stats: null
};

const promoLoadFinished = (state, action) => {
    return updateObject(
        state,
        {
            promos: action.data.promos,
            stats: action.data.stats
        }
    );
};

const reducer = ( state = initialState, action ) => {
    switch ( action.type ) {
        case actionTypes.PROMO_LOAD_FINISH: return promoLoadFinished(state, action);
        default:
            return state;
    }
};

export default reducer;
import {updateObject} from "../../shared/utility";
import * as actionTypes from "./clientBlackListActions";

const initialState = {
    ips: null,
};

const clientBlackListLoaded = (state, action) => {
    return updateObject(
        state,
        action.payload
    );
};


const reducer = ( state = initialState, action ) => {
    switch ( action.type ) {
        case actionTypes.CLIENT_BLACKLIST_LOADED: return clientBlackListLoaded(state, action);
        default:
            return state;
    }
};

export default reducer;
import * as actionTypes from "./paymentActions";
import { updateObject } from "../../shared/utility";


const initialState = {};

const paymentLoadFinished = (state, action) => {
    return updateObject(
        state,
        action.payload
    );
};

const updatePaymentSuccess = (state, action) => {
    return updateObject(
        state,
        {
            updatePaymentResult: 'success',
            errorMessage: null,
        }
    );
}

const updatePaymentFail = (state, action) => {
    return updateObject(
        state,
        {
            updatePaymentResult: 'fail',
            errorMessage: action.payload,
        }
    );
}

const deletePaymentSuccess = (state, action) => {
    return updateObject(
        state,
        {
            deletePaymentResult: 'success',
            errorMessage: null,
        }
    );
}

const deletePaymentFail = (state, action) => {
    return updateObject(
        state,
        {
            deletePaymentResult: 'fail',
            errorMessage: action.payload,
        }
    );
}


const reducer = ( state = initialState, action ) => {
    switch ( action.type ) {
        case actionTypes.PAYMENT_LOAD_FINISH: return paymentLoadFinished(state, action);
        case actionTypes.UPDATE_PAYMENT_SUCCESS: return updatePaymentSuccess(state, action);
        case actionTypes.UPDATE_PAYMENT_FAILED: return updatePaymentFail(state, action);
        case actionTypes.DELETE_PAYMENT_SUCCESS: return deletePaymentSuccess(state, action);
        case actionTypes.DELETE_PAYMENT_FAILED: return deletePaymentFail(state, action);
        default:
            return state;
    }
};

export default reducer;
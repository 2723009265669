export const MANAGERS_LOAD = 'MANAGERS_LOAD';
export const MANAGERS_LOAD_FINISH = 'MANAGERS_LOAD_FINISH';

export const MANAGER_ADD = "MANAGER_ADD";
export const MANAGER_ADD_FAILED = "MANAGER_ADD_FAILED";
export const MANAGER_ADD_SUCCESS = "MANAGER_ADD_SUCCESS";

export const MANAGER_UPDATE = "MANAGER_UPDATE";
export const MANAGER_UPDATE_FAILED = "MANAGER_UPDATE_FAILED";
export const MANAGER_UPDATE_SUCCESS = "MANAGER_UPDATE_SUCCESS";

export const MANAGER_DELETE = "MANAGER_DELETE";
export const MANAGER_DELETE_FAILED = "MANAGER_DELETE_FAILED";
export const MANAGER_DELETE_SUCCESS = "MANAGER_DELETE_SUCCESS";

export const managersLoad = (token) => {
    return {
        type: MANAGERS_LOAD,
        token: token,
    }
}

export const managersLoadFinish = (payload) => {
    return {
        type: MANAGERS_LOAD_FINISH,
        payload: payload
    }
};

export const managerAdd = (token, payload) => {
    return {
        type: MANAGER_ADD,
        token: token,
        payload: payload
    }
};

export const managerAddFail = (error) => {
    return {
        type: MANAGER_ADD_FAILED,
        payload: error
    }
};

export const managerAddSuccess = (payload) => {
    return {
        type: MANAGER_ADD_SUCCESS,
        payload: payload
    }
};

export const managerUpdate = (token, payload) => {
    return {
        type: MANAGER_UPDATE,
        token: token,
        payload: payload
    }
};

export const managerUpdateSuccess= (payload) => {
    return {
        type: MANAGER_UPDATE_SUCCESS,
        payload: payload
    }
};

export const managerUpdateFail = (error) => {
    return {
        type: MANAGER_UPDATE_FAILED,
        payload: error
    }
};

export const managerDelete = (token, payload) => {
    return {
        type: MANAGER_DELETE,
        token: token,
        payload: payload
    }
};

export const managerDeleteSuccess = (payload) => {
    return {
        type: MANAGER_DELETE_SUCCESS,
        payload: payload
    }
};

export const managerDeleteFail = (error) => {
    return {
        type: MANAGER_DELETE_FAILED,
        payload: error
    }
};
export const API_KEY_LOAD = 'API_KEY_LOAD';
export const API_KEY_LOAD_FINISH = 'API_KEY_LOAD_FINISH';

export const API_KEY_GENERATE = "API_KEY_GENERATE";
export const API_KEY_GENERATE_FAILED = "API_KEY_GENERATE_FAILED";
export const API_KEY_GENERATE_SUCCESS = "API_KEY_GENERATE_SUCCESS";

export const API_KEY_DELETE = "API_KEY_DELETE";
export const API_KEY_DELETE_FAILED = "API_KEY_DELETE_FAILED";
export const API_KEY_DELETE_SUCCESS = "API_KEY_DELETE_SUCCESS";

export const API_SWAGGER_CONFIG_LOAD = "API_SWAGGER_CONFIG_LOAD";
export const API_SWAGGER_CONFIG_LOAD_FINISH = "API_SWAGGER_CONFIG_LOAD_FINISH";

export const apiKeyLoad = (token) => {
    return {
        type: API_KEY_LOAD,
        token: token,
    }
}

export const apiKeyLoadFinish = (payload) => {
    return {
        type: API_KEY_LOAD_FINISH,
        payload: payload
    }
};

export const apiSwaggerConfigLoad = (token) => {
    return {
        type: API_SWAGGER_CONFIG_LOAD,
        token: token
    }
};

export const apiSwaggerConfigLoadFinish = (payload) => {
    return {
        type: API_SWAGGER_CONFIG_LOAD_FINISH,
        payload: payload
    }
};

export const apiKeyGenerate = (token) => {
    return {
        type: API_KEY_GENERATE,
        token: token,
    }
};

export const apiKeyGenerateFail = (error) => {
    return {
        type: API_KEY_GENERATE_FAILED,
        payload: error
    }
};

export const apiKeyGenerateSuccess = (payload) => {
    return {
        type: API_KEY_GENERATE_SUCCESS,
        payload: payload
    }
};

export const apiKeyDelete = (token, payload) => {
    return {
        type: API_KEY_DELETE,
        token: token,
        payload: payload
    }
};

export const apiKeyDeleteSuccess = (payload) => {
    return {
        type: API_KEY_DELETE_SUCCESS,
        payload: payload
    }
};

export const apiKeyDeleteFail = (error) => {
    return {
        type: API_KEY_DELETE_FAILED,
        payload: error
    }
};
import React, { Component} from "react";
import { connect } from 'react-redux';
import { Link, Redirect } from "react-router-dom";

import { Row, Col, Form, Input, Button, Checkbox, Alert} from "antd";

import LeftContent from "../leftContent";
import * as actions from "../../../../redux/auth/authActions";
import HeaderLanguge from "../../../../layout/components/header/HeaderLanguage";
import {withTranslation} from "react-i18next";

class Login extends Component {
    onFinish = (values) => {
        console.log('Received values of form: ', values);
    }

    onClose() {}

    render() {
        const {t} = this.props;
        let redirectPage = this.props.authRedirectPath;
        if (this.props.location.state && this.props.location.state.from) {
            redirectPage = this.props.location.state.from;
        }

        let authRedirect = null;
        if ( this.props.token && this.props.token_type ) {
            authRedirect = <Redirect to={redirectPage} />
        }

        let alert = null;
        if (this.props.location.state && this.props.location.state.message) {
            alert = <Alert
                className="hp-mt-16"
                message={t(this.props.location.state.messageType)}
                description={t(this.props.location.state.message)}
                type="info"
                closable
                onClose={this.onClose}
            />
        }

        if (this.props.error) {
            let message = this.props.errorMessage ? this.props.errorMessage : "Uknown error";

            alert = <Alert
                className="hp-mt-16"
                message={t("Login Error")}
                description={t(message)}
                type="error"
                closable
                onClose={this.onClose}
            />
        }
        return (
            <Row gutter={[32, 0]} className="hp-authentication-page" style={{height:"100vh"}}>
                <LeftContent/>

                {authRedirect}

                <Col lg={12} span={24} className="hp-py-sm-0 hp-py-md-64">
                    <Row>
                        <Col offset={20} span={4} align="right">
                            <Row style={{paddingTop: "20px"}}>
                                <HeaderLanguge lang="en" />
                            </Row>
                        </Col>
                    </Row>
                    <Row className="hp-h-100" align="middle" justify="center" gutter={[32, {xxl: 11, xl:15, lg: 20, md: 20, sm: 24}]}>
                        <Col
                            span={20}
                            className="hp-px-sm-8 hp-pt-24 hp-pb-48"
                        >
                            <h1 className="hp-mb-sm-0">{t("Login")}</h1>
                            <p className="hp-mt-sm-0 hp-mt-8 hp-text-color-black-60">
                                {t("Welcome back")}
                            </p>
                            {alert}
                            <Form
                                layout="vertical"
                                name="basic"
                                initialValues={{remember: true}}
                                className="hp-mt-sm-16 hp-mt-32"
                                onFinish={this.props.onLogin}
                            >
                                <Form.Item
                                    label={t("Username")}
                                    className="hp-mb-16"
                                    name="email"
                                >
                                    <Input id="error"/>
                                </Form.Item>

                                <Form.Item label={t("Password")} className="hp-mb-8" name="password">
                                    <Input.Password id="warning2"/>
                                </Form.Item>

                                <Row align="middle" justify="space-between">


                                    <Link
                                        className="hp-button hp-text-color-black-80 hp-text-color-dark-40"
                                        to="/authentication/recover-password"
                                    >
                                        {t("Forgot Password")}
                                    </Link>
                                </Row>

                                <Form.Item className="hp-mt-16 hp-mb-8">
                                    <Button block type="primary" htmlType="submit" className="login-form-button">
                                        {t("Log in")}
                                    </Button>
                                </Form.Item>
                            </Form>

                            <Col className="hp-form-info">
                                <span className="hp-text-color-black-80 hp-text-color-dark-40 hp-caption hp-mr-4">
                                    {t("No account")}
                                </span>
                                <Link
                                    className="hp-text-color-primary-1 hp-text-color-dark-primary-2 hp-caption"
                                    to="/authentication/register"
                                >
                                    {t("Create an account")}
                                </Link>
                            </Col>
                        </Col>
                    </Row>
                </Col>
            </Row>
        );
    }
}

const mapStateToProps = state => {
  return {
    token: state.auth.token,
    token_type: state.auth.token_type,
    error: state.auth.error,
    errorMessage: state.auth.errorMessage,
    authRedirectPath: "/"
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onLogin: (values) => dispatch( actions.login(values) )
  };
};


export default connect( mapStateToProps, mapDispatchToProps )(withTranslation()(Login));
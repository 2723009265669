import { takeEvery, takeLatest } from "redux-saga/effects";
import axios from "axios";

import * as profileActions from "./profile/profileActions";
import {
    getNotificationSettingsSaga,
    loadProfileSaga,
    saveNotificationSettingsSaga,
    updateProfileSaga
} from "./profile/profileSaga";

import * as authActionTypes from "./auth/authActions"
import * as authSagas from "./auth/authSaga";

import * as promoActions from "./promo/promoActions";
import {addPromo, deletePromoSaga, loadPromoSaga} from "./promo/promoSaga";

import * as referralActions from "./referral/referralActions";
import * as referralSaga from "./referral/referralSaga";

import * as tariffActions from "./tariff/tariffActions";
import * as tariffSaga from "./tariff/tariffSaga";

import * as paymentActions from "./payments/paymentActions";
import * as paymentSaga from "./payments/paymentSaga";

import * as clientActions from "./clients/clientActions";
import * as clientSaga from "./clients/clientSaga";

import * as integrationActions from "./integration/integrationActions";
import * as integrationSaga from "./integration/integrationSaga";

import * as logsActions from "./logs/logsActions";
import * as logsSaga from "./logs/logsSaga";

import * as campaignActions from "./campaign/campaignActions";
import * as campaignSaga from "./campaign/campaignSaga";

import * as blackListActions from "./blacklist/blackListActions";
import * as blackListSaga from "./blacklist/blackListSaga";

import * as referralClientsActions from "./referralClients/referralClientsActions";
import * as referralClientsSaga from "./referralClients/referralClientsSaga";

import * as clientBlackListActions from "./clientBlacklist/clientBlackListActions";
import * as clientBlackListSaga from "./clientBlacklist/clientBlackListSaga";

import * as notifActions from "./notifications/notificationsActions";
import * as notifSaga from "./notifications/notificationsSaga";

import * as teamUsersActions from "./teamUsers/teamUsersActions";
import * as teamUsersSaga from "./teamUsers/teamUsersSaga";

import * as apiActions from "./api/apiActions";
import * as apiSaga from "./api/apiSaga";

import * as conversionActions from "./conversion/conversionActions";
import * as conversionSaga from "./conversion/conversionSaga";

import * as expirationsActions from "./expirations/expirationsActions";
import * as expirationsSaga from "./expirations/expirationsSaga";

import * as banActions from "./ban/banActions";
import * as banSaga from "./ban/banSaga";

import * as managersActions from "./managers/managersActions";
import * as managersSaga from "./managers/managersSaga";

axios.defaults.baseURL = process.env.REACT_APP_API_URL;

export function* watchProfileSaga() {
    yield takeLatest(profileActions.PROFILE_LOAD, loadProfileSaga);
    yield takeEvery(profileActions.PROFILE_UPDATE, updateProfileSaga);

    yield takeLatest(profileActions.PROFILE_GET_NOTIFICATION_SETTINGS, getNotificationSettingsSaga);
    yield takeLatest(profileActions.PROFILE_SAVE_NOTIFICATION_SETTINGS, saveNotificationSettingsSaga);
}

export function* watchPromoSaga() {
    yield takeLatest(promoActions.PROMO_LOAD, loadPromoSaga);
    yield takeLatest(promoActions.PROMO_DELETE, deletePromoSaga);
    yield takeLatest(promoActions.PROMO_ADD, addPromo);
}

export function* watchReferralSaga() {
    yield takeLatest(referralActions.REFERRAL_LOAD, referralSaga.referralLoadSaga);
    yield takeLatest(referralActions.ROYALTY_ADD, referralSaga.addRoyalty);
}

export function* watchTariffSaga() {
    yield takeLatest(tariffActions.TARIFF_LOAD, tariffSaga.tariffLoadSaga);
    yield takeLatest(tariffActions.TARIFF_ADD, tariffSaga.addTariff);
    yield takeLatest(tariffActions.TARIFF_DELETE, tariffSaga.deleteTariffSaga);

    yield takeLatest(tariffActions.TARIFF_CHANGE, tariffSaga.changeTariffSaga);
}

export function* watchCampaignSaga() {
    yield takeLatest(campaignActions.CAMPAIGN_LOAD, campaignSaga.campaignLoadSaga);
    yield takeLatest(campaignActions.DOWNLOAD_WP_INTEGRATION, campaignSaga.downloadWpIntegration);
    yield takeLatest(campaignActions.DOWNLOAD_INTEGRATION, campaignSaga.downloadIntegration);
    yield takeLatest(campaignActions.DOWNLOAD_SITE, campaignSaga.downloadSite);

    yield takeLatest(campaignActions.CAMPAIGN_EDIT_DATA, campaignSaga.campaignEditData);
    yield takeLatest(campaignActions.CAMPAIGN_SAVE_DATA, campaignSaga.campaignSaveData);
    yield takeLatest(campaignActions.CAMPAIGN_DELETE, campaignSaga.campaignDelete);
    yield takeLatest(campaignActions.CAMPAIGN_COPY, campaignSaga.campaignCopy);
    yield takeLatest(campaignActions.CAMPAIGN_NAME_CHANGE, campaignSaga.campaignNameChange);
    yield takeLatest(campaignActions.CAMPAIGN_UPDATE_INFO_STATUS, campaignSaga.campaignUpdateInfoStatus);
}

export function* watchClientSaga() {
    yield takeLatest(clientActions.CLIENTS_LOAD, clientSaga.clientsLoadSaga);
    yield takeEvery(clientActions.CLIENTS_SET_ACTIVATION, clientSaga.clientsSetActivation);
    yield takeEvery(clientActions.CLIENT_ADD_PAYMENT, clientSaga.clientAddPayment);
    yield takeEvery(clientActions.CLIENT_ADD_NOTES, clientSaga.clientAddNotes);
    yield takeEvery(clientActions.CLIENT_UPDATE_EMAIL, clientSaga.clientUpdateEmail);
}

export function* watchBlackListSaga() {
    yield takeLatest(blackListActions.BLACKLIST_LOAD, blackListSaga.blackListLoadSaga);
    yield takeLatest(blackListActions.BLACKLIST_ADD, blackListSaga.addBlackList);
}

export function* watchClientBlackListSaga() {
    yield takeLatest(clientBlackListActions.CLIENT_BLACKLIST_LOAD, clientBlackListSaga.clientBlackListLoadSaga);
    yield takeLatest(clientBlackListActions.CLIENT_BLACKLIST_ADD, clientBlackListSaga.addClientBlackList);
}

export function* watchReferralClientsSaga() {
    yield takeLatest(referralClientsActions.REFERRAL_CLIENTS_LOAD, referralClientsSaga.referralClientsLoadSaga);
}

export function* watchIntegrationSaga() {
    yield takeLatest(integrationActions.INTEGRATION_LOAD, integrationSaga.loadIntegrationSaga);
    yield takeEvery(integrationActions.INTEGRATION_EDIT, integrationSaga.integrationEditSaga);
}

export function* watchLogSaga() {
    yield takeLatest(logsActions.GET_CLIENT_LIST, logsSaga.getClienListSaga);
    yield takeLatest(logsActions.GET_CLIENT_COMPANIES, logsSaga.getClienCompaniesSaga);
    yield takeLatest(logsActions.GET_COMPANY_LOGS, logsSaga.getCompanyLogsSaga);

    yield takeLatest(logsActions.GET_USER_COMPANIES, logsSaga.getUserCompaniesSaga);
    yield takeLatest(logsActions.GET_USER_LOGS, logsSaga.getUserLogsSaga);
    yield takeLatest(logsActions.GET_USER_COMPANY_FILTER, logsSaga.getUserCompanyFilterSaga);

    yield takeLatest(logsActions.GET_COMPANY_TOTALS, logsSaga.loadCompanyTotalsSaga);
    yield takeLatest(logsActions.GET_COMPANY_DAILY, logsSaga.loadCompanyDailysSaga);
    yield takeLatest(logsActions.GET_COMPANY_PLATFORM, logsSaga.loadCompanyPlatformsSaga);

    yield takeLatest(logsActions.GET_REQUEST_STATISTIC, logsSaga.loadRequestStatisticSaga);
    yield takeLatest(logsActions.GET_TOP_REQUEST_STATISTIC, logsSaga.loadTopRequestStatisticSaga);
}

export function* watchPaymentSaga() {
    yield takeLatest(paymentActions.PAYMENT_LOAD, paymentSaga.paymentLoadSaga);
    yield takeEvery(paymentActions.UPDATE_PAYMENT, paymentSaga.updatePayment);
    yield takeEvery(paymentActions.DELETE_PAYMENT, paymentSaga.deletePayment);
}

export function* watchAuthSaga() {
    yield takeEvery(authActionTypes.LOGIN, authSagas.authLoginSaga);
    yield takeEvery(authActionTypes.REGISTER, authSagas.authRegisterSaga);
    yield takeEvery(authActionTypes.AUTH_CHECK_STATE, authSagas.authCheckStateSaga);
    yield takeEvery(authActionTypes.AUTH_INITIATE_LOGOUT, authSagas.logoutSaga);
    yield takeEvery(authActionTypes.CHECK_AUTH_TIMEOUT, authSagas.checkAuthTimeoutSaga);
    yield takeEvery(authActionTypes.RECOVER_PASSWORD, authSagas.authRecoverPasswordSaga);
    yield takeEvery(authActionTypes.RESET_PASSWORD, authSagas.authResetPasswordSaga);

    yield takeLatest(authActionTypes.CONFIRM_EMAIL, authSagas.confirmEmailSaga);
}

export function* watchNotificationSaga() {
    yield takeLatest(notifActions.GET_NOTIFICATIONS, notifSaga.getNotificationsSaga);
    yield takeEvery(notifActions.CLEAR_NOTIFICATIONS, notifSaga.clearNotificationsSaga);
    yield takeLatest(notifActions.ADD_NOTIFICATION, notifSaga.addNotificationsSaga);
}

export function* watchTeamUsersSaga() {
    yield takeLatest(teamUsersActions.TEAM_USERS_LOAD, teamUsersSaga.teamUsersLoadSaga);
    yield takeEvery(teamUsersActions.TEAM_USER_ADD, teamUsersSaga.addTeamUserSaga);
    yield takeEvery(teamUsersActions.TEAM_USER_UPDATE, teamUsersSaga.updateTeamUserSaga);
    yield takeEvery(teamUsersActions.TEAM_USER_DELETE, teamUsersSaga.deleteTeamUserSaga);
}

export function* watchApiSaga() {
    yield takeLatest(apiActions.API_KEY_LOAD, apiSaga.apiKeyLoadSaga);
    yield takeLatest(apiActions.API_SWAGGER_CONFIG_LOAD, apiSaga.apiSwaggerConfigLoadSaga);
    yield takeEvery(apiActions.API_KEY_GENERATE, apiSaga.apiKeyGenerateSaga);
    yield takeEvery(apiActions.API_KEY_DELETE, apiSaga.apiKeyDeleteSaga);
}

export function* watchConversionSaga() {
    yield takeLatest(conversionActions.CONVERSION_LOAD, conversionSaga.loadConversionSaga);
    yield takeLatest(conversionActions.CONVERSION_FILTER_CHANGE, conversionSaga.loadConversionOnFilterChange);

    yield takeLatest(conversionActions.CONVERSION_FIELDS_LOAD, conversionSaga.loadConversionFieldsSaga);
    yield takeLatest(conversionActions.CONVERSION_FIELDS_CHANGE, conversionSaga.loadConversionOnFieldsChange);
}

export function* watchExpirationsSaga() {
    yield takeLatest(expirationsActions.EXPIRATIONS_LOAD, expirationsSaga.loadExpirationsSaga);
}

export function* watchBanSaga() {
    yield takeLatest(banActions.BAN_LOAD, banSaga.banLoadSaga);
    yield takeLatest(banActions.BAN_ADD, banSaga.banAddSaga);
    yield takeLatest(banActions.BAN_REMOVE, banSaga.banRemoveSaga);
}

export function* watchManagersSaga() {
    yield takeLatest(managersActions.MANAGERS_LOAD, managersSaga.managersLoadSaga);
    yield takeEvery(managersActions.MANAGER_ADD, managersSaga.addManagerSaga);
    yield takeEvery(managersActions.MANAGER_UPDATE, managersSaga.updateManagerSaga);
    yield takeEvery(managersActions.MANAGER_DELETE, managersSaga.deleteManagerSaga);
}

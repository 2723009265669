import {put} from "@redux-saga/core/effects";
import * as actions from "./paymentActions";
import axios from "axios";

export function* paymentLoadSaga(action){
    let config = {
        headers: {
            Authorization: "Bearer " + action.token,
            Accept: "application/json"
        }
    };

    try {
        const response = yield axios.post(
            "/v1/payments",
            action.payload,
            config
        );
        yield put(actions.paymentLoadFinish(response.data.result));

    } catch (error) {

    }
}

export function* updatePayment(action) {
    let config = {
        headers: {
            Authorization: "Bearer " + action.token,
            Accept: "application/json"
        }
    };

    try {
        const response = yield axios.post(
            "/v1/payments/updatePayment",
            action.payload,
            config
        );

        if (response.data.result.success) {
            yield put(actions.updatePaymentSuccess(response.data.result));
        } else {
            yield put(actions.updatePaymentFail(response.data.result.message.errors));
        }

    } catch (error) {

    }
}

export function* deletePayment(action) {
    let config = {
        headers: {
            Authorization: "Bearer " + action.token,
            Accept: "application/json"
        }
    };

    try {
        const response = yield axios.post(
            "/v1/payments/deletePayment",
            action.payload,
            config
        );

        if (response.data.result.success) {
            yield put(actions.deletePaymentSuccess(response.data.result));
        } else {
            yield put(actions.deletePaymentFail(response.data.result.message.errors));
        }

    } catch (error) {

    }
}
import {updateObject} from "../../shared/utility";
import * as actionTypes from "./campaignActions";

const initialState = {
    campaign: null,
    campaignEdit: null,
    companies: null,
    errorMessage: null,
    siteDownloadStatus: null,
};


const campaignLoadDone = (state, action) => {
    return updateObject(
        state,
        action.payload.data
    );
};

const campaignEditDataDone = (state, action) => {
    return updateObject(
        state,
        {campaignEdit: action.payload.data}
    )
};

const campaignEditClear = (state, action) => {
    return updateObject(state, {campaignEdit: null});
};

const campaignNameChanged = (state, action) => {
    const companies = [...state.companies];
    companies.map(c => {
       if (c.id == action.campaignId) {
           c.name = action.name;
       }
       return c;
    });

    return updateObject(
        state,
        {companies: companies}
    );
};

const campaignSaveFailed = (state, action) => {
    let message = '';
    if (action.payload !== undefined) {
        message = action.payload;
    }

    return updateObject(
        state,
        {
            error: true,
            errorMessage: message
        }
    );
}

const siteDownloadStatusUpdate = (state, action) => {
    return updateObject(state, {siteDownloadStatus: action.payload});
};


const reducer = ( state = initialState, action ) => {
    switch ( action.type ) {
        case actionTypes.CAMPAIGN_LOAD_DONE: return campaignLoadDone(state, action);
        case actionTypes.CAMPAIGN_EDIT_DATA_DONE: return campaignEditDataDone(state, action);
        case actionTypes.CAMPAIGN_EDIT_DATA_CLEAR: return campaignEditClear(state, action);
        case actionTypes.CAMPAIGN_NAME_CHANGED: return campaignNameChanged(state, action);
        case actionTypes.CAMPAIGN_SAVE_DATA_FAIL: return campaignSaveFailed(state, action);
        case actionTypes.SITE_DOWNLOAD_STATUS_UPDATE: return siteDownloadStatusUpdate(state, action);
        default:
            return state;
    }
};

export default reducer;
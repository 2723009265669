import {put} from "@redux-saga/core/effects";
import * as actions from "./referralActions";
import * as systemActions from "../system/systemActions";
import axios from "axios";

export function* referralLoadSaga(action){
    let config = {
        headers: {
            Authorization: "Bearer " + action.token,
            Accept: "application/json"
        }
    };

    try {
        const response = yield axios.get(
            "/v1/referrals?startDate=" + action.start + "&endDate=" + action.end,
            config
        );
        yield put(actions.referralLoadFinish(response.data.result));

    } catch (error) {

    }
}

export function* addRoyalty(action) {
    let config = {
        headers: {
            Authorization: "Bearer " + action.token,
            Accept: "application/json"
        }
    };

    try {
        const response = yield axios.post(
            "/v1/royalty",
            action.payload,
            config
        );

        if (response.data.result && response.data.result.success) {
            yield put(systemActions.systemNotification(response.data.result.message));
            yield put(actions.referralLoad(action.token, action.payload.startDate, action.payload.endDate));
        } else {
            yield put(systemActions.axiosError(response.data.result));
        }

    } catch (error) {

    }
}
import { Link, Redirect } from "react-router-dom";
import { useParams } from 'react-router';
import { connect } from 'react-redux';

import { Row, Col, Form, Input, Button, Alert } from "antd";

import LeftContent from "../leftContent";

import * as actions from "../../../../redux/auth/authActions";
import React from "react";
import HeaderLanguge from "../../../../layout/components/header/HeaderLanguage";
import {withTranslation} from "react-i18next";

const ResetPassword = (props) => {

    const {t} = props;

    let authRedirect = null;
    if (props.resetStatus == 'success') {
        authRedirect = <Redirect to = {{
            pathname: "/authentication/login"
        }}/>
    }

    let alerts = null;
    if (props.error) {
        let messages = props.errorMessage ? props.errorMessage : ["Uknown error"];
        alerts = renderAlerts(messages);
    }

    function renderAlerts(messages) {
        const {t} = props;
        let alerts = messages.map((message, i) => {
            return (
                <Alert
                    key={i}
                    className="hp-mt-16"
                    message={t("Reset Password")}
                    description={t(message)}
                    type="error"
                    closable
                />
            );
        });

        return alerts;
    }

    const { token } = useParams();
    const handleSubmit = (values) => {
        const queryParams = new URLSearchParams(window.location.search);
        values.token = token;
        values.email = queryParams.get('email');
        props.onResetPassword(values);
    }

    return (
        <Row gutter={[32, 0]} className="hp-authentication-page" style={{height:"100vh"}}>
            <LeftContent />
            {authRedirect}
            <Col md={12}>
                <Row>
                    <Col offset={20} span={4} align="right">
                        <Row style={{"paddingTop": "20px"}}>
                            <HeaderLanguge lang="en" />
                        </Row>
                    </Col>
                </Row>
                <Row className="hp-h-100" align="middle" justify="center" gutter={[32, {xxl: 11, xl:15, lg: 20, md: 20, sm: 24}]}>
                    <Col
                        span={20}
                        className="hp-px-sm-8 hp-pt-24 hp-pb-48"
                    >
                        <h1 className="hp-mb-sm-0">{t('Reset Password')}</h1>
                        <p className="hp-mt-sm-0 hp-mt-8 hp-text-color-black-60">
                            {t("Email verification is done. Please choose another password:")}
                        </p>
                        {alerts}
                        <Form
                            layout="vertical"
                            name="basic"
                            className="hp-mt-sm-16 hp-mt-32"
                            initialValues={{remember: true}}
                            onFinish={(values) => handleSubmit(values)}
                        >
                            <Form.Item label={t("Password")} name="password">
                                <Input.Password
                                    id="password"
                                    placeholder={t("At least 6 characters")}
                                />
                            </Form.Item>

                            <Form.Item label={t("Confirm Password")} name="password_confirmation" >
                                <Input.Password
                                    id="confirm-password"
                                    placeholder={t("At least 6 characters")}
                                />
                            </Form.Item>

                            <Form.Item className="hp-mt-16 hp-mb-8">
                                <Button block type="primary" htmlType="submit">
                                    {t("Reset Password")}
                                </Button>
                            </Form.Item>
                        </Form>

                        <div className="hp-form-info">
                            <span className="hp-text-color-black-80 hp-text-color-dark-40 hp-caption hp-mr-4">
                                {t("Go back to")}
                            </span>

                            <Link
                                to="/authentication/login"
                                className="hp-text-color-primary-1 hp-text-color-dark-primary-2 hp-caption"
                                >
                                {t("Login")}
                            </Link>
                        </div>
                    </Col>
                </Row>
            </Col>
        </Row>
    );
}

const mapStateToProps = state => {
    return {
        error: state.auth.error,
        errorMessage: state.auth.errorMessage,
        resetStatus: state.auth.resetStatus,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onResetPassword: (values) => dispatch( actions.resetPassword(values) ),
    };
};

export default connect( mapStateToProps, mapDispatchToProps )( withTranslation()(ResetPassword) );

import {put} from "@redux-saga/core/effects";
import * as actions from "./integrationActions";
import * as systemActions from "../system/systemActions";
import axios from "axios";

export function* loadIntegrationSaga(action) {
    let config = {
        headers: {
            Authorization: "Bearer " + action.token,
            Accept: "application/json"
        }
    };

    try {
        const response = yield axios.get(
            "/v1/integration?lang=" + action.language,
            config
        );
        yield put(actions.integrationLoadFinish(response.data.result));

    } catch (error) {

    }
};

export function* integrationEditSaga(action) {

    let config = {
        headers: {
            Authorization: "Bearer " + action.token,
            Accept: "application/json"
        }
    }

    try {
        const response = yield axios.post(
            "/v1/integration/edit",
            {
                language: action.language,
                number: action.payload.index,
                sectionContent: action.payload.content,
                category: action.category
            },
            config
        );

        if (response.data.result && response.data.result.success) {
            yield put(systemActions.systemNotification(response.data.result.message));
            yield put(actions.integrationEdited(action));
        } else {
            yield put(systemActions.axiosError(response.data.result));
        }

    } catch (error) {

    }
};
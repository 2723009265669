import React, {Component} from "react";

import {Col, Dropdown, Menu} from "antd";

import Flags from 'country-flag-icons/react/1x1';
import i18next from "i18next";

import {languageComponents} from "../../../configs/languages";
import { RiArrowDropDownLine } from "react-icons/ri";
import * as actions from "../../../redux/integration/integrationActions";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import {withTranslation} from "react-i18next";

class HeaderLanguge extends Component{

    state = {
        language: 'en'
    }

    componentDidMount() {
        let storage = localStorage.getItem("i18nextLng");
        let language = storage ? storage : 'en';
        this.setState({language: language});
    }

    onChange = lang => {
        if (this.state.language !== lang &&
            (this.props.location.pathname === "/pages/faq" ||  this.props.location.pathname === "/pages/integration")
        ) {
            this.props.onLanguageChange(
                this.props.authToken,
                lang
            );
        }

        i18next.changeLanguage(lang);
        this.setState({language: lang});
    }

    render() {
        const {t} = this.props;
        let language = 'Eng';
        for (let x in languageComponents) {
            let block = languageComponents[x];
            if (this.state.language == block.lang) {
                language = block.name
            }
        }


        const menu = (
            <Menu>
                {languageComponents.map(block => (
                    <Menu.Item key={block.lang}>
                        <a onClick={() => this.onChange(block.lang)}>
                            {t(block.name)}
                        </a>
                    </Menu.Item>
                    )
                )}
            </Menu>
        );

        return (
            <Col className="hp-d-flex-center hp-mr-sm-12 hp-mr-16">
                <Dropdown overlay={menu}>
                    <a
                        className="ant-dropdown-link"
                        onClick={(e) => e.preventDefault()}
                    >
                        {t(language)} <RiArrowDropDownLine className="remix-icon" size={24} />
                    </a>
                </Dropdown>
            </Col>
        );
    }
}

const mapStateToProps = state => {
    return {
        authToken: state.auth.token,
    }
};

const mapDispatchToProps = dispatch => {
    return {
        onLanguageChange: (token, language) => dispatch(actions.integrationLoad(token, language)),
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(withTranslation()(HeaderLanguge)));
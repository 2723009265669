import {put} from "@redux-saga/core/effects";
import * as actions from "./managersActions";
import axios from "axios";

export function* managersLoadSaga(action) {
    let config = {
        headers: {
            Authorization: "Bearer " + action.token,
            Accept: "application/json"
        }
    };

    try {
        const response = yield axios.get(
            "/v1/managers/getManagers",
            config
        );
        yield put(actions.managersLoadFinish(response.data.result));

    } catch (error) {

    }
}

export function* addManagerSaga(action) {

    let config = {
        headers: {
            Authorization: "Bearer " + action.token,
            Accept: "application/json"
        }
    }

    try {
        const response = yield axios.post(
            "/v1/managers/addManager",
            action.payload,
            config
        );
        if (response.data.result.success) {
            yield put(actions.managerAddSuccess(response.data.result));
        } else {
            yield put(actions.managerAddFail(response.data.result.message.errors));
        }
    } catch (error) {

    }
}

export function* updateManagerSaga(action) {

    let config = {
        headers: {
            Authorization: "Bearer " + action.token,
            Accept: "application/json"
        }
    }

    try {
        const response = yield axios.post(
            "/v1/managers/updateManager",
            action.payload,
            config
        );
        if (response.data.result.success) {
            yield put(actions.managerUpdateSuccess(response.data.result));
        } else {
            yield put(actions.managerUpdateFail(response.data.result.message.errors));
        }
    } catch (error) {

    }
}

export function* deleteManagerSaga(action) {

    let config = {
        headers: {
            Authorization: "Bearer " + action.token,
            Accept: "application/json"
        }
    }

    try {
        const response = yield axios.post(
            "/v1/managers/deleteManager",
            action.payload,
            config
        );
        if (response.data.result.success) {
            yield put(actions.managerDeleteSuccess(response.data.result));
        } else {
            yield put(actions.managerDeleteFail(response.data.result.message.errors));
        }
    } catch (error) {

    }
}
import React from "react";
import { useLocation, Link } from "react-router-dom";
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import {
    Menu,
} from "antd";

import navigation from "../../../../navigation/vertical";

const { SubMenu } = Menu;

export default function MenuItem(props) {

    const { onClose } = props;
    const { t } = useTranslation();
    // Redux
    const customise = useSelector(state => state.customise)
    const menu = useSelector((state) => state.auth.menu);
    const profile = useSelector((state) => state.profile);

    // Location
    const location = useLocation();
    const { pathname } = location;

    const splitLocation = pathname.split("/")

    // Menu
    const splitLocationUrl =
        splitLocation[splitLocation.length - 2] +
        "/" +
        splitLocation[splitLocation.length - 1];

    let menuItem = null;

    let isActive = true;
    if (profile.profile) {
        isActive = profile.profile.isActive;
    }

    if (menu) {
        menuItem = navigation.map((item, index) => {

            if (item.title != undefined && !menu.includes(item.title)) {
                return null;
            }

            if (item.header) {
                return <Menu.ItemGroup key={index} title={item.header}></Menu.ItemGroup>;
            }

            if (item.children) {
                return (
                    <SubMenu key={item.id} icon={item.icon} title={item.title}>
                        {item.children.map((childrens, index) => {
                            if (!childrens.children) {
                                const childrenNavLink = childrens.navLink.split("/");

                                return (
                                    // Level 2
                                    <Menu.Item
                                        key={childrens.id}
                                        className={
                                            splitLocationUrl ===
                                            childrenNavLink[childrenNavLink.length - 2] +
                                            "/" +
                                            childrenNavLink[childrenNavLink.length - 1]
                                                ? "ant-menu-item-selected"
                                                : "ant-menu-item-selected-in-active"
                                        }
                                        onClick={onClose}
                                    >
                                        <Link to={childrens.navLink}>{childrens.title}</Link>
                                    </Menu.Item>
                                );
                            } else {
                                return (
                                    // Level 3
                                    <SubMenu key={childrens.id} title={childrens.title}>
                                        {childrens.children.map((childItem, index) => {
                                            const childrenItemLink = childItem.navLink.split("/");

                                            return (
                                                <Menu.Item
                                                    key={childItem.id}
                                                    className={
                                                        splitLocationUrl ===
                                                        childrenItemLink[childrenItemLink.length - 2] +
                                                        "/" +
                                                        childrenItemLink[childrenItemLink.length - 1]
                                                            ? "ant-menu-item-selected"
                                                            : "ant-menu-item-selected-in-active"
                                                    }
                                                    onClick={onClose}
                                                >
                                                    <Link to={childItem.navLink}>{childItem.title}</Link>
                                                </Menu.Item>
                                            );
                                        })}
                                    </SubMenu>
                                );
                            }
                        })}
                    </SubMenu>
                );
            } else {
                const itemNavLink = item.navLink.split("/");
                let disabledItem = false;
                if (item.navLink == '/pages/integration' && isActive === false) {
                    disabledItem = true;
                }

                let offset = 1;
                if (item.id === "profile") {
                    offset = 2
                }
                let className =
                        splitLocation[splitLocation.length - (1 + offset)] +
                        "/" +
                        splitLocation[splitLocation.length - offset] ===
                        itemNavLink[itemNavLink.length - (1 + offset)] +
                        "/" +
                        itemNavLink[itemNavLink.length - offset]
                            ? "ant-menu-item-selected"
                            : "ant-menu-item-selected-in-active";

                return (
                    // Level 1
                    <Menu.Item
                        title={t(item.title)}
                        key={item.id}
                        icon={item.icon}
                        onClick={onClose}
                        disabled={disabledItem}
                        className={className}
                    >
                        <Link to={item.navLink}>{t(item.title)}</Link>
                    </Menu.Item>
                );
            }
        })
    }

    return (
        <Menu
            mode="inline"
            defaultOpenKeys={[
                splitLocation.length === 5
                    ? splitLocation[splitLocation.length - 3]
                    : null,
                splitLocation[splitLocation.length - 2],
            ]}
            theme={customise.theme == "light" ? "light" : "dark"}
        >
            {menuItem}
        </Menu>
    );
};
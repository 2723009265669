export const CLIENT_BLACKLIST_LOAD = 'CLIENT_BLACKLIST_LOAD';
export const CLIENT_BLACKLIST_LOADED = 'CLIENT_BLACKLIST_LOADED';
export const CLIENT_BLACKLIST_ADD = 'CLIENT_BLACKLIST_ADD'

export const clientBlackListLoad = (token) => {
    return {
        type: CLIENT_BLACKLIST_LOAD,
        token: token,
    }
};

export const clientBlackListLoaded = (payload) => {
    return {
        type: CLIENT_BLACKLIST_LOADED,
        payload: payload
    }
};

export const clientBlackListAdd = (token, payload) => {
    return {
        type: CLIENT_BLACKLIST_ADD,
        token: token,
        payload: payload
    }
};
export const BLACKLIST_LOAD = 'BLACKLIST_LOAD';
export const BLACKLIST_LOADED = 'BLACKLIST_LOADED';
export const BLACKLIST_ADD = 'BLACKLIST_ADD'

export const blackListLoad = (token) => {
    return {
        type: BLACKLIST_LOAD,
        token: token,
    }
};

export const blackListLoaded = (payload) => {
    return {
        type: BLACKLIST_LOADED,
        payload: payload
    }
};

export const blackListAdd = (token, payload) => {
    return {
        type: BLACKLIST_ADD,
        token: token,
        payload: payload
    }
};
import {updateObject} from "../../shared/utility";
import * as actionTypes from "./referralClientsActions";

const initialState = {
    refPromo: null,
    users: [],
    startDate: null,
    endDate: null,
    royalties: []
};

const referralClientsLoaded = (state, action) => {
    return updateObject(
        state,
        action.payload
    );
};


const reducer = ( state = initialState, action ) => {
    switch ( action.type ) {
        case actionTypes.REFERRAL_CLIENTS_LOADED: return referralClientsLoaded(state, action);
        default:
            return state;
    }
};

export default reducer;
export const CAMPAIGN_LOAD = "CAMPAIGN_LOAD";
export const CAMPAIGN_LOAD_DONE = "CAMPAIGN_LOAD_DONE";

export const DOWNLOAD_INTEGRATION = "DOWNLOAD_INTEGRATION";
export const DOWNLOAD_WP_INTEGRATION = "DOWNLOAD_WP_INTEGRATION";

export const DOWNLOAD_SITE = "DOWNLOAD_SITE";
export const SITE_DOWNLOAD_STATUS_UPDATE = "SITE_DOWNLOAD_STATUS_UPDATE";

export const CAMPAIGN_EDIT_DATA = "CAMPAIGN_EDIT_DATA";
export const CAMPAIGN_EDIT_DATA_CLEAR = "CAMPAIGN_EDIT_DATA_START";
export const CAMPAIGN_EDIT_DATA_DONE = "CAMPAIGN_EDIT_DATA_DONE";

export const CAMPAIGN_SAVE_DATA = "CAMPAIGN_SAVE_DATA";
export const CAMPAIGN_SAVE_DATA_DONE = "CAMPAIGN_SAVE_DATA_DONE";
export const CAMPAIGN_SAVE_DATA_FAIL = "CAMPAIGN_SAVE_DATA_FAIL";

export const CAMPAIGN_DELETE = "CAMPAIGN_DELETE";

export const CAMPAIGN_COPY = "CAMPAIGN_COPY";
export const CAMPAIGN_NAME_CHANGE = "CAMPAIGN_NAME_CHANGE";
export const CAMPAIGN_NAME_CHANGED = "CAMPAIGN_NAME_CHANGED";

export const CAMPAIGN_UPDATE_INFO_STATUS = "CAMPAIGN_UPDATE_INFO_STATUS";

export const campaignLoad = (token, payload) => {
    return {
        type: CAMPAIGN_LOAD,
        token: token,
        payload: payload
    };
};

export const campaignLoadDone = (payload) => {
    return {
        type: CAMPAIGN_LOAD_DONE,
        payload: payload
    }
};

export const downloadWpIntegration = (token, id) => {
    return {
        type: DOWNLOAD_WP_INTEGRATION,
        token: token,
        campaign: id,
    }
};

export const downloadIntegration = (token, id) => {
    return {
        type: DOWNLOAD_INTEGRATION,
        token: token,
        campaign: id
    }
};

export const downloadSite = (token, payload) => {
    return {
        type: DOWNLOAD_SITE,
        token: token,
        payload: payload
    }
};

export const siteDownloadStatusUpdate = (payload) => {
    return {
        type: SITE_DOWNLOAD_STATUS_UPDATE,
        payload: payload,
    }
};


export const campaignEditData = (token, id) => {
    return {
        type: CAMPAIGN_EDIT_DATA,
        token: token,
        campaign: id
    }
};

export const campaignEditClear = () => {
    return {
        type: CAMPAIGN_EDIT_DATA_CLEAR
    }
};

export const campaignEditDataDone = (payload) => {
    return {
        type: CAMPAIGN_EDIT_DATA_DONE,
        payload: payload
    }
};

export const campaignDataDelete = (token, payload) => {
    return {
        type: CAMPAIGN_DELETE,
        token: token,
        payload: payload
    }
};

export const campaignSaveData = (token, payload, callback, error) => {
    return {
        type: CAMPAIGN_SAVE_DATA,
        token: token,
        payload: payload,
        callback: callback,
        error: error
    }
};

export const campaignSaveDataDone = (payload) => {
    return {
        type: CAMPAIGN_SAVE_DATA_DONE,
        payload: payload
    }
};

export const campaignCopy = (token, campaignId, history) => {
    return {
        type: CAMPAIGN_COPY,
        token: token,
        campaignId: campaignId,
        history: history,
    }
};

export const campaignUpdateInfoStatus = (token, campaignId) => {
    return {
        type: CAMPAIGN_UPDATE_INFO_STATUS,
        token: token,
        campaignId: campaignId,
    }
};

export const campaignNameChange = (token, payload) => {
    return {
        type: CAMPAIGN_NAME_CHANGE,
        token: token,
        payload: payload
    }
};

export const campaignNameChanged = (campaignId, name) => {
    return {
        type: CAMPAIGN_NAME_CHANGED,
        campaignId: campaignId,
        name: name,
    }
}

export const campaignSaveFailed = (error) => {
    return {
        type: CAMPAIGN_SAVE_DATA_FAIL,
        payload: error
    }
};
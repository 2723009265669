import {put} from "@redux-saga/core/effects";
import * as actions from "./clientBlackListActions";
import * as systemActions from "../system/systemActions";
import axios from "axios";

export function* clientBlackListLoadSaga(action) {
    let config = {
        headers: {
            Authorization: "Bearer " + action.token,
            Accept: "application/json"
        }
    };

    try {
        const response = yield axios.get(
            "/v1/clientblacklist",
            config
        );
        
        yield put(actions.clientBlackListLoaded(response.data.result));

    } catch (error) {

    }
}

export function* addClientBlackList(action) {

    let config = {
        headers: {
            Authorization: "Bearer " + action.token,
            Accept: "application/json"
        }
    }

    let response = null;

    try {
        response = yield axios.post(
            "/v1/clientblacklist/add",
            action.payload,
            config
        );

        if (response.data.result && response.data.result.success) {
            yield put(actions.clientBlackListLoad(action.token));
            yield put(systemActions.systemNotification(response.data.result.message));
        } else {
            yield put(systemActions.axiosError(response.data.result));
        }

    } catch (error) {
        console.log("Error:", error)
    }
}


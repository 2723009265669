import { Suspense, useEffect } from "react";

// Redux
import { useDispatch, useSelector } from "react-redux";
import { theme } from "../redux/customise/customiseActions";

// Router
import {
    BrowserRouter, Redirect,
    Route,
    Switch,
    useHistory,
    useLocation,
    useRouteMatch,
} from "react-router-dom";

// Routes
import { Routes } from "./routes";

// Layouts
import VerticalLayout from "../layout/VerticalLayout";
import HorizontalLayout from "../layout/HorizontalLayout";
import FullLayout from "../layout/FullLayout";

// Components
import Error404 from "../view/pages/error";
import Login from "../view/pages/authentication/login";
import Register from "../view/pages/authentication/register";
import RecoverPassword from "../view/pages/authentication/recover-password";
import ResetPassword from "../view/pages/authentication/reset-password";
import ConfirmEmail from "../view/pages/authentication/confirm-email";


export default function Router() {
    // Redux
    const customise = useSelector(state => state.customise)
    const auth = useSelector(state => state.auth);

    const dispatch = useDispatch()

    // Location
    const location = useHistory()

    // Dark Mode
    useEffect(() => {
        document.querySelector("body").classList.add(customise.theme)
        dispatch(theme(customise.theme))
    }, [])

    // RTL
    useEffect(() => {
        if (customise.direction == "ltr") {
            document.querySelector("html").setAttribute("dir", "ltr");
        } else if (customise.direction == "rtl") {
            document.querySelector("html").setAttribute("dir", "rtl");
        }
    }, [])

    // Default Layout
    const DefaultLayout = customise.layout; // FullLayout or VerticalLayout

    // All of the available layouts
    //const Layouts = { VerticalLayout, HorizontalLayout, FullLayout };
    const Layouts = { VerticalLayout };

    // Return Filtered Array of Routes & Paths
    const LayoutRoutesAndPaths = (layout) => {
        const LayoutRoutes = [];
        const LayoutPaths = [];
        if (Routes) {
            // Checks if Route layout or Default layout matches current layout
            Routes.filter(route => {
                if (route.layout === layout) {
                    if (!route.auth || (auth.menu && auth.menu.includes(route.title))) {
                        LayoutRoutes.push(route);
                        LayoutPaths.push(route.path);
                    }
                }
            });
        }

        return { LayoutRoutes, LayoutPaths };
    };

    const getFirstPage = () => {
        let redirectPath = '/';
        if (auth.menu) {
            let pageName = auth.menu[0];
            if (Routes) {
                Routes.filter(route => {
                    if (route.title == pageName) {
                        redirectPath = route.path;
                    }
                });
            }
        }

        return redirectPath;
    }

    // Return Route to Render
    let ResolveRoutes = () => {
        return Object.keys(Layouts).map((layout, index) => {
            const { LayoutRoutes, LayoutPaths } = LayoutRoutesAndPaths(layout);

            let LayoutTag;
            if (DefaultLayout == "HorizontalLayout") {
                if (layout == "VerticalLayout") {
                    LayoutTag = Layouts["HorizontalLayout"];
                } else {
                    LayoutTag = Layouts[layout];
                }
            } else {
                LayoutTag = Layouts[layout];
            }

            return (
                <Route path={LayoutPaths} key={index}>
                    <LayoutTag>
                        <Switch>
                            {LayoutRoutes.map((route) => {
                                return (
                                    <Route
                                        key={route.path}
                                        path={route.path}
                                        exact={route.exact === true}
                                        render={(props) => {
                                            return (
                                                <Suspense fallback={null}>
                                                    <route.component {...props} />
                                                </Suspense>
                                            );
                                        }}
                                    />
                                );
                            })}
                        </Switch>
                    </LayoutTag>
                </Route>
            );
        });
    };


    let home = <Route
        exact
        path={'/'}
        render={() => {
            let redirectPath = getFirstPage();
            return (
                DefaultLayout == "HorizontalLayout" ? (
                    <Layouts.HorizontalLayout>
                        <Redirect to={redirectPath}/>
                    </Layouts.HorizontalLayout>
                ) : (
                    <Layouts.VerticalLayout>
                        <Redirect to={redirectPath}/>
                    </Layouts.VerticalLayout>
                )
            )
        }}
    />

    let error404 = <Route path='*'>
        <Error404 />
    </Route>

    let authPage = null;
    let redirect = null;
    let registerPage = null;
    let recoverPasswordPage = null;
    let resetPasswordPage = null;
    let confirmEmail = null;


    if (!auth.token || !auth.token_type) {
        authPage = <Route path={'/authentication/login'} component={Login} />
        registerPage = <Route path={'/authentication/register'} component={Register} />
        recoverPasswordPage = <Route path={'/authentication/recover-password'} component={RecoverPassword} />
        resetPasswordPage = <Route path={'/authentication/reset-password/:token'} component={ResetPassword} />
        confirmEmail = <Route path={'/authentication/confirm-email/:id/:token'} component={ConfirmEmail} />

        // clear default routes
        ResolveRoutes = () => {
            return []
        }
        const location = useLocation();

        let useRedirect = false;
        let matchedRoute = null;

        Routes.map((route) => {
            if (useRouteMatch(route.path)) {
                matchedRoute = route;
            }
        });

        if (matchedRoute) {
            if (matchedRoute.auth === true 
                || (matchedRoute.auth === false && auth.actionType)
            ) {
                useRedirect = true;
            }
        } else {
            useRedirect = true;
        }

        if (useRedirect) {
            redirect = <Redirect to={{
                pathname: '/authentication/login',
                state: { from: location.pathname }
            }}/>
        }
    }

    return (
        <BrowserRouter>
            <Switch>
                {ResolveRoutes()}

                {home}

                {authPage}
                {registerPage}
                {recoverPasswordPage}
                {resetPasswordPage}
                {confirmEmail}
                {error404}

            </Switch>

            {redirect}

        </BrowserRouter>
    );
};
import { ConfigProvider } from 'antd';

import Router from "./router/Router";
import React, {Component} from "react";
import { connect } from "react-redux";

import * as actions from "./redux/auth/authActions"
import * as systemActions from "./redux/system/systemActions";

import {withRouter} from "react-router-dom";
import axios from "axios";

class App extends Component {

    componentWillMount(){
        const self = this;
        axios.interceptors.request.use(function (config) {
            // spinning start to show
            self.props.axiosStart();
            return config
        }, function (error) {
            self.props.axiosError("some error!!! ");
            return Promise.reject(error);
        });

        axios.interceptors.response.use(function (response) {
            // spinning hide
            self.props.axiosEnd();

            return response;
        }, function (error) {
            self.props.axiosError(error);
            return Promise.reject(error);
        });
    }


    componentDidMount () {
        require('dotenv').config();

        this.props.onTryAutoSignup();

    }
    render() {
        const customise = this.props.customise;
        return (
            <ConfigProvider direction={customise.direction}>
                <Router />
            </ConfigProvider>
        );
    }

}

const mapPropsToState = state => {
    return {
        customise: state.customise,
        authToken: state.auth.token,
        authTokenType: state.auth.token_type,
        auth: state.auth,
        menu: null,

    }
}

const mapDispatchToProps = dispatch => {
    return {
        onTryAutoSignup: () => dispatch(actions.authCheckState()),
        axiosStart: () => dispatch(systemActions.axiosStart()),
        axiosEnd: () => dispatch(systemActions.axiosEnd()),
        axiosError: (data) => dispatch(systemActions.axiosError(data))
    };
};


export default connect(mapPropsToState, mapDispatchToProps)(withRouter(App));
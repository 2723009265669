import { Link } from "react-router-dom";

import { Dropdown, Col, Avatar, Divider, Row } from "antd";
import { User } from "react-iconly";

import {useDispatch} from "react-redux";
import { logout } from '../../../redux/auth/authActions'
import {withTranslation} from "react-i18next";

function HeaderUser(props) {
  const dispatch = useDispatch();
  const  { t } = props;

  const menu = (
    <div className="hp-border-radius hp-border-1 hp-border-color-black-40 hp-bg-black-0 hp-bg-dark-100 hp-border-color-dark-80 hp-p-24 hp-mt-12" style={{ width: 260 }}>
      <span className="hp-d-block h5 hp-text-color-black-100 hp-text-color-dark-0 hp-mb-8">{t("Profile Settings")}</span>
        <Link
            to="/pages/profile/personal-information"
            className="hp-p1-body hp-text-color-primary-1 hp-text-color-dark-primary-2 hp-hover-text-color-primary-2"
        >
            {t("View Profile")}
        </Link>
        <Divider className="hp-mb-16 hp-mt-6" />
        <Link to="/" onClick={() => dispatch(logout())} className="hp-p1-body">
            {t("Logout")}
        </Link>
    </div>
  );

  return (
    <Col>
      <Dropdown overlay={menu} placement="bottomLeft">
        <Avatar
            size={48}
            icon={<User set="curved" className="remix-icon" />}
            className="hp-cursor-pointer ant-avatar ant-avatar-circle" />
      </Dropdown>
    </Col>
  );
};

export default withTranslation()(HeaderUser);

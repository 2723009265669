import {updateObject} from "../../shared/utility";
import * as actionTypes from "./managersActions";

const initialState = {
    managers: null,
    errorMessage: null,
    addMaangerResponse: null,
    updateManagerResponse: null,
    deleteManagerResponse: null,
};

const managersLoadFinished = (state, action) => {
    return updateObject(
        state,
        action.payload
    );
};

const managerAddSuccess = (state, action) => {
    return updateObject(
        state,
        {
            addManagerResponse: 'success',
            managers: action.payload.managers,
            errorMessage: null,
        }
    );
}

const managerAddFail = (state, action) => {
    return updateObject(
        state,
        {
            addManagerResponse: 'fail',
            errorMessage: action.payload,
        }
    );
}

const managerUpdateSuccess = (state, action) => {
    return updateObject(
        state,
        {
            updateManagerResponse: 'success',
            managers: action.payload.managers,
            errorMessage: null,
        }
    );
}

const managerUpdateFail = (state, action) => {
    return updateObject(
        state,
        {
            updatManagerResponse: 'fail',
            errorMessage: action.payload,
        }
    );
}

const managerDeleteSuccess = (state, action) => {
    return updateObject(
        state,
        {
            deleteManagerResponse: 'success',
            managers: action.payload.managers,
            errorMessage: null,
        }
    );
}

const managerDeleteFail = (state, action) => {
    return updateObject(
        state,
        {
            deleteManagerResponse: 'fail',
            errorMessage: action.payload,
        }
    );
}

const reducer = ( state = initialState, action ) => {
    switch ( action.type ) {
        case actionTypes.MANAGERS_LOAD_FINISH: return managersLoadFinished(state, action);
        case actionTypes.MANAGER_ADD_FAILED: return managerAddFail(state, action);
        case actionTypes.MANAGER_ADD_SUCCESS: return managerAddSuccess(state, action);
        case actionTypes.MANAGER_UPDATE_FAILED: return managerUpdateFail(state, action);
        case actionTypes.MANAGER_UPDATE_SUCCESS: return managerUpdateSuccess(state, action);
        case actionTypes.MANAGER_DELETE_FAILED: return managerDeleteFail(state, action);
        case actionTypes.MANAGER_DELETE_SUCCESS: return managerDeleteSuccess(state, action);
        default:
            return state;
    }
};

export default reducer;
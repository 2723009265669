export const PAYMENT_LOAD = 'PAYMENT_LOAD';
export const PAYMENT_LOAD_FINISH = 'PAYMENT_LOAD_FINISH';

export const UPDATE_PAYMENT = "UPDATE_PAYMENT";
export const UPDATE_PAYMENT_SUCCESS = "UPDATE_PAYMENT_SUCCESS";
export const UPDATE_PAYMENT_FAILED = "UPDATE_PAYMENT_FAILED";

export const DELETE_PAYMENT = "DELETE_PAYMENT";
export const DELETE_PAYMENT_SUCCESS = "DELETE_PAYMENT_SUCCESS";
export const DELETE_PAYMENT_FAILED = "DELETE_PAYMENT_FAILED";

export const paymentLoad = (token, payload) => {
    return {
        type: PAYMENT_LOAD,
        token: token,
        payload: payload
    }
}

export const paymentLoadFinish = (payload) => {
    return {
        type: PAYMENT_LOAD_FINISH,
        payload: payload
    }
};

export const updatePayment = (token, payload) => {
    return {
        type: UPDATE_PAYMENT,
        token: token,
        payload: payload
    }
};

export const updatePaymentSuccess = (payload) => {
    return {
        type: UPDATE_PAYMENT_SUCCESS,
        payload: payload
    }
};

export const updatePaymentFail = (error) => {
    return {
        type: UPDATE_PAYMENT_FAILED,
        payload: error
    }
};

export const deletePayment = (token, payload) => {
    return {
        type: DELETE_PAYMENT,
        token: token,
        payload: payload
    }
};

export const deletePaymentSuccess = (payload) => {
    return {
        type: DELETE_PAYMENT_SUCCESS,
        payload: payload
    }
};

export const deletePaymentFail = (error) => {
    return {
        type: DELETE_PAYMENT_FAILED,
        payload: error
    }
};
import {updateObject} from "../../shared/utility";
import * as actionTypes from "./tariffActions";

const initialState = {
    tariffs: null
};


const tariffLoadFinished = (state, action) => {
    return updateObject(
        state,
        action.payload
    );
};


const reducer = ( state = initialState, action ) => {
    switch ( action.type ) {
        case actionTypes.TARIFF_LOAD_FINISH: return tariffLoadFinished(state, action);
        default:
            return state;
    }
};

export default reducer;
import {put} from "@redux-saga/core/effects";
import * as actions from "./logsActions";
import axios from "axios";

export function* getClienListSaga(action){
    let config = {
        headers: {
            Authorization: "Bearer " + action.token,
            Accept: "application/json"
        }
    };

    try {
        const response = yield axios.get(
            "/v1/logs/clients",
            config
        );
        yield put(actions.getClientListDone(response.data.result));

    } catch (error) {

    }
}

export function* getClienCompaniesSaga(action){

    let config = {
        headers: {
            Authorization: "Bearer " + action.token,
            Accept: "application/json"
        }
    };

    try {
        const response = yield axios.get(
            "/v1/logs/companies?email=" + action.client,
            config
        );
        yield put(actions.getClientCompaniesDone(response.data.result));

    } catch (error) {

    }
}

export function* getCompanyLogsSaga(action){

    yield put(actions.getCompanyLogsLoading());

    let config = {
        headers: {
            Authorization: "Bearer " + action.token,
            Accept: "application/json"
        }
    };

    try {
        const response = yield axios.post(
            "/v1/logs/logs",
            action.payload,
            config
        );
        yield put(actions.getCompanyLogsDone(response.data.result));

    } catch (error) {

    }
}

export function* getUserCompaniesSaga(action) {
    let config = {
        headers: {
            Authorization: "Bearer " + action.token,
            Accept: "application/json"
        }
    };

    try {
        const response = yield axios.get(
            "/v1/logs/userCompanies",
            config
        );
        yield put(actions.getClientCompaniesDone(response.data.result));

    } catch (error) {

    }
}

export function* getUserCompanyFilterSaga(action) {
    let config = {
        headers: {
            Authorization: "Bearer " + action.token,
            Accept: "application/json"
        }
    };

    try {
        const response = yield axios.get(
            "/v1/logs/userCompanyFilter?companyId=" + action.companyId + "&startDate=" + action.startDate + "&endDate=" + action.endDate,
            config
        );

        yield put(actions.getClientCompanyFilterDone(response.data.result));

    } catch (error) {

    }
}

export function* getUserLogsSaga(action){

    yield put(actions.getCompanyLogsLoading());

    let config = {
        headers: {
            Authorization: "Bearer " + action.token,
            Accept: "application/json"
        }
    };

    try {
        const response = yield axios.post(
            "/v1/logs/userLogs",
            action.payload,
            config
        );
        yield put(actions.getCompanyLogsDone(response.data.result));

    } catch (error) {

    }
}

export function* loadCompanyTotalsSaga(action){

    let config = {
        headers: {
            Authorization: "Bearer " + action.token,
            Accept: "application/json"
        }
    };

    try {
        const response = yield axios.post(
            "/v1/logs/companyTotals",
            action.payload,
            config
        );
        yield put(actions.loadCompanyTotalsDone(response.data.result));

    } catch (error) {

    }
}

export function* loadCompanyDailysSaga(action){

    let config = {
        headers: {
            Authorization: "Bearer " + action.token,
            Accept: "application/json"
        }
    };

    try {
        const response = yield axios.post(
            "/v1/logs/companyDaily",
            action.payload,
            config
        );
        yield put(actions.loadCompanyDailyDone(response.data.result));

    } catch (error) {

    }
}

export function* loadCompanyPlatformsSaga(action){

    let config = {
        headers: {
            Authorization: "Bearer " + action.token,
            Accept: "application/json"
        }
    };

    try {
        const response = yield axios.post(
            "/v1/logs/companyPlatforms",
            action.payload,
            config
        );
        yield put(actions.loadCompanyPlatformsDone(response.data.result));

    } catch (error) {

    }
}

export function* loadRequestStatisticSaga(action) {

    let config = {
        headers: {
            Authorization: "Bearer " + action.token,
            Accept: "application/json"
        }
    };

    try {
        const response = yield axios.post(
            "/v1/logs/getRequestStatistic",
            action.payload,
            config
        );
        yield put(actions.loadRequestStatisticDone(response.data.result));

    } catch (error) {

    }
}

export function* loadTopRequestStatisticSaga(action) {

    let config = {
        headers: {
            Authorization: "Bearer " + action.token,
            Accept: "application/json"
        }
    };

    try {
        const response = yield axios.post(
            "/v1/logs/getTopRequestStatistic",
            action.payload,
            config
        );
        yield put(actions.loadTopRequestStatisticDone(response.data.result));

    } catch (error) {

    }
}
export const CONVERSION_LOAD = 'CONVERSION_LOAD';
export const CONVERSION_LOAD_FINISH = 'CONVERSION_LOAD_FINISH';

export const CONVERSION_FILTER_CHANGE = 'CONVERSION_FILTER_CHANGE';
export const CONVERSION_FILTER_CHANGE_FINISH = 'CONVERSION_FILTER_CHANGE_FINISH';

export const CONVERSION_FIELDS_LOAD = 'CONVERSION_FIELDS_LOAD';
export const CONVERSION_FIELDS_LOAD_FINISH = 'CONVERSION_FIELDS_LOAD_FINISH';

export const CONVERSION_FIELDS_CHANGE = 'CONVERSION_FIELDS_CHANGE';


export const conversionLoad = (token) => {
    return {
        type: CONVERSION_LOAD,
        token: token
    }
};

export const conversionLoadFinish = (data) => {
    return {
        type: CONVERSION_LOAD_FINISH,
        data: data
    }
};

export const conversionFilterChange = (token, values) => {
    return {
        type: CONVERSION_FILTER_CHANGE,
        token: token,
        values: values
    }
};

export const conversionFilterChangeFinish = (data) => {
    return {
        type: CONVERSION_FILTER_CHANGE_FINISH,
        data: data
    }
};

export const conversionFieldsLoad = (token) => {
    return {
        type: CONVERSION_FIELDS_LOAD,
        token: token
    }
};

export const conversionFieldsLoadFinish = (data) => {
    return {
        type: CONVERSION_FIELDS_LOAD_FINISH,
        data: data
    };
};

export const conversionFieldsChange = (token, data) => {
    return {
        type: CONVERSION_FIELDS_CHANGE,
        token: token,
        data: data
    };
};
import { lazy } from "react";

const PagesRoutes = [
    {
        path: "/pages/blank-page",
        component: lazy(() => import("../../view/pages/blank")),
        layout: "VerticalLayout",
        title: 'Blank Page',
    },
    {
        path: "/pages/error-page",
        component: lazy(() => import("../../view/pages/error")),
        layout: "VerticalLayout",
        title: 'Error Page',
    },
    {
        path: "/authentication/confirm-email/:id/:token",
        component: lazy(() => import("../../view/pages/authentication/confirm-email")),
        layout: "VerticalLayout",
        title: 'Confirm Email',
    },
    {
        path: "/pages/campaign-page/change/:id?",
        component: lazy(() => import("../../view/pages/campaign/changeCampaign")),
        layout: "VerticalLayout",
        title: 'Campaign Change',
        auth: true
    },
    {
        path: "/pages/campaign-page",
        component: lazy(() => import("../../view/pages/campaign")),
        layout: "VerticalLayout",
        title: 'Campaign',
        auth: true  // !!!!!!!!  ADD THIS IS PROHIBITED FROM USER ROLE !!!!!
    },
    {
        path: "/pages/ban",
        component: lazy(() => import("../../view/pages/ban")),
        layout: "VerticalLayout",
        title: 'Ban Companies',
        auth: true
    },
    {
        path: "/pages/profile",
        component: lazy(() => import("../../view/pages/profile")),
        layout: "VerticalLayout",
        title: 'Profile',
        auth: true,
    },

    // additional profile pages
    {
        path: "/pages/profile/personal-information",
        component: lazy(() => import("../../view/pages/profile/personal-information")),
        layout: "VerticalLayout",
        title: 'Profile Personal Information',
        auth: true,
    },

    {
        path: "/pages/notifications",
        component: lazy(() => import("../../view/pages/notifications")),
        layout: "VerticalLayout",
        title: 'Notifications',
        auth: true,
    },
    {
        path: "/pages/promo",
        component: lazy(() => import("../../view/pages/promo")),
        layout: "VerticalLayout",
        title: 'Promo',
        auth: true,
    },
    {
        path: "/pages/clients",
        component: lazy(() => import("../../view/pages/clients")),
        layout: "VerticalLayout",
        title: 'Clients',
        auth: true,
    },
    {
        path: "/pages/referrals",
        component: lazy(() => import("../../view/pages/referrals")),
        layout: "VerticalLayout",
        title: 'Referrals',
        auth: true,
    },
    {
        path: "/pages/payments",
        component: lazy(() => import("../../view/pages/payments")),
        layout: "VerticalLayout",
        title: 'Payments',
        auth: true,
    },
    {
        path: "/pages/conversion",
        component: lazy(() => import("../../view/pages/conversion")),
        layout: "VerticalLayout",
        title: 'Conversions',
        auth: true,
    },
    {
        path: "/pages/expirations",
        component: lazy(() => import("../../view/pages/expirations")),
        layout: "VerticalLayout",
        title: 'Expirations',
        auth: true,
    },
    {
        path: "/pages/tariff",
        component: lazy(() => import("../../view/pages/tariff")),
        layout: "VerticalLayout",
        title: 'Tariff',
        auth: true,
    },
    {
        path: "/pages/tariffs",
        component: lazy(() => import("../../view/pages/tariff/userTariffs")),
        layout: "VerticalLayout",
        title: 'Tariffs',
        auth: true,
    },
    {
        path: "/pages/faq",
        component: lazy(() => import("../../view/pages/faq")),
        layout: "VerticalLayout",
        title: 'FAQ',
        auth: true,
    },
    {
        path: "/pages/integration",
        component: lazy(() => import("../../view/pages/faq/userIntegration")),
        layout: "VerticalLayout",
        title: 'Integration',
        auth: true,
    },
    {
        path: "/pages/referralClients",
        component: lazy(() => import("../../view/pages/referralClients")),
        layout: "VerticalLayout",
        title: 'Referral Clients',
        auth: true,
    },
    {
        path: "/pages/blacklist",
        component: lazy(() => import("../../view/pages/blacklist")),
        layout: "VerticalLayout",
        title: 'Black List',
        auth: true,
    },
    {
        path: "/pages/clientBlacklist",
        component: lazy(() => import("../../view/pages/clientBlacklist")),
        layout: "VerticalLayout",
        title: 'BlackList',
        auth: true,
    },
    {
        path: "/pages/logs",
        component: lazy(() => import("../../view/pages/logs")),
        layout: "VerticalLayout",
        title: 'Logs',
        auth: true,
    },
    {
        path: "/pages/teamUsers",
        component: lazy(() => import("../../view/pages/teamUsers")),
        layout: "VerticalLayout",
        title: 'Team Users',
        auth: true,
    },
    {
        path: "/pages/requestStatistic",
        component: lazy(() => import("../../view/pages/requestStatistic")),
        layout: "VerticalLayout",
        title: 'Request Statistic',
        auth: true,
    },
    {
        path: "/pages/statistics/:id?",
        component: lazy(() => import("../../view/pages/statistics")),
        layout: "VerticalLayout",
        title: 'Statistics',
        auth: true,
    },
    {
        path: "/pages/api",
        component: lazy(() => import("../../view/pages/api")),
        layout: "VerticalLayout",
        title: 'API',
        auth: true,
    },
    {
        path: "/pages/managers",
        component: lazy(() => import("../../view/pages/managers")),
        layout: "VerticalLayout",
        title: 'Managers',
        auth: true,
    },
    {
        path: "/authentication/login",
        component: lazy(() => import("../../view/pages/authentication/login")),
        layout: "VerticalLayout",
        auth: false,
    },
    {
        path: "/authentication/register",
        component: lazy(() => import("../../view/pages/authentication/register")),
        layout: "VerticalLayout",
        auth: false,
    },
    {
        path: "/authentication/recover-password",
        component: lazy(() => import("../../view/pages/authentication/recover-password")),
        layout: "VerticalLayout",
        auth: false,
    },
    {
        path: "/authentication/reset-password/:token",
        component: lazy(() => import("../../view/pages/authentication/reset-password")),
        layout: "VerticalLayout",
        auth: false,
    }
];

export default PagesRoutes;
import React, {useState, useEffect} from "react";

import {useSelector, useDispatch, connect} from "react-redux";

import { Button, Badge, Row, Col, Dropdown, Divider, Avatar } from "antd";
import { Notification, TickSquare, CloseSquare, Danger } from "react-iconly";
import {FiTrash} from "react-icons/fi";
import {withTranslation} from "react-i18next";

import * as notifActions from "../../../redux/notifications/notificationsActions";

const HeaderNotifications = (props) => {

    const direction = useSelector(state => state.customise.direction);
    const [notifications, setNotifications] = useState([]);
    const  { t } = props;

    useEffect(() => {
        props.onNotificationsLoad(props.authToken);
    }, []);

    useEffect(() => {
        setNotifications(props.notifications);
    }, [props.notifications]);

    let notificationMenu = null;
    let clearButton = null;
    let badge = <Badge />

    if (notifications) {
        if (notifications.length) {
            clearButton =  <Button
                block
                ghost
                onClick={() =>  props.onNotificationClear(props.authToken)}
                className="hp-text-color-primary-1 hp-text-color-dark-primary-2 hp-hover-bg-primary-4 hp-hover-bg-dark-80 hp-mt-4"
                icon={<FiTrash className="remix-icon"/>}
                >
                {t("Clear all notifications")}
                </Button>
        }

        notificationMenu = (
            <div
                className="hp-border-radius hp-border-1 hp-border-color-black-40 hp-bg-black-0 hp-bg-dark-100 hp-border-color-dark-80 hp-pt-24 hp-pb-18 hp-px-18 hp-mt-18"
                style={{width: '288px', height: '600px', overflowY:'scroll'}}>
                <Row align="middle" justify="space-between" className="hp-mb-18">
                    <Col className="h5 hp-text-color-black-100 hp-text-color-dark-10 hp-text-color-dark-0 hp-mr-64">
                        {t("Notifications")}
                    </Col>

                    <Col
                    className="hp-bg-color-primary-1 hp-border-radius-full hp-badge-text hp-text-color-black-0 hp-py-4 hp-px-6 hp-ml-24">
                    {notifications.length} {t("notificationCount")}
                    </Col>
                </Row>

                <Divider className="hp-my-10"/>

                {
                    notifications.map((e, k) => {

                        let ava = <Avatar
                            size={38}
                            icon={<TickSquare size={16} className="hp-text-color-success-1" />}
                            className="hp-d-flex-center-full hp-bg-success-4"
                        />

                        if (e.type == "danger") {
                            ava = <Avatar
                                size={38}
                                icon={<CloseSquare size={16} className="hp-text-color-danger-1" />}
                                className="hp-d-flex-center-full hp-bg-danger-4"
                            />
                        } else if (e.type == "warning") {
                            ava = <Avatar
                            size={38}
                            icon={<Danger size={16} className="hp-text-color-warning-1" />}
                            className="hp-d-flex-center-full hp-bg-warning-4"
                                />
                        }

                        return(
                            <Row key={k} gutter={16} align="middle">
                                <Col span={4}>
                                    {ava}
                                </Col>
                                <Col span={20}>
                                    <span className="notification-date">
                                        {e.created}
                                    </span>
                                    <span className="hp-d-block hp-w-100 hp-mb-4 hp-font-weight-500 hp-p1-body notification-title">
                                        {e.title}
                                    </span>
                                    <span className="hp-d-block hp-badge-text hp-mb-4 hp-text-color-black-100">
                                        {e.message}
                                    </span>
                                </Col>
                                <Divider className="hp-my-10" />
                            </Row>
                        )
                    })

                }
                {clearButton}
            </div>
        );

        if (notifications.length) {
            badge = <Badge dot status={"processing"} />
        }
    }

    return (
    <Col className="hp-d-flex-center hp-mr-sm-12 hp-mr-16">
        <Button
        type="text"
        icon={
            <Dropdown overlay={notificationMenu} placement="bottomRight">
                <div className="hp-position-relative">
                    <div className="hp-position-absolute" style={direction == "rtl" ? { left: -5, top: -5 } : { right: 4, top: -5 }}>
                        {badge}
                    </div>

                    <Notification
                        set="curved"
                        className="hp-text-color-black-60"
                    />
                </div>
            </Dropdown>
        }
        />
    </Col>
    );
};

const mapStateToProps = state => {
    return {
        authToken: state.auth.token,
        notifications: state.notifications.notifications,
    }
};

const mapDispatchToProps = dispatch => {
    return {
        onNotificationsLoad: (token) => dispatch(notifActions.getNotifications(token)),
        onNotificationClear: (token) => dispatch(notifActions.clearNotifications(token)),
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(HeaderNotifications));
export const BAN_LOAD = 'BAN_LOAD';
export const BAN_LOADED = 'BAN_LOADED';
export const BAN_ADD = 'BAN_ADD'
export const BAN_REMOVE = 'BAN_REMOVE';

export const banLoad = (token) => {
    return {
        type: BAN_LOAD,
        token: token,
    }
};

export const banLoaded = (payload) => {
    return {
        type: BAN_LOADED,
        payload: payload
    }
};

export const banAdd = (token, payload) => {
    return {
        type: BAN_ADD,
        token: token,
        payload: payload
    }
};

export const banRemove = (token, payload) => {
    return {
        type: BAN_REMOVE,
        token: token,
        payload: payload
    }
};
import {updateObject} from "../../shared/utility";
import * as actionTypes from "./clientActions";

const initialState = {
    clients: null
};

const clientLoadFinished = (state, action) => {
    return updateObject(
        state,
        action.payload
    );
};

const clientsSetActivationDone = (state, action) => {
    let clients = [...state.clients];
    let row = {...clients[action.payload.index]};
    row.activation = +action.payload.activation;
    clients[action.payload.index] = row;

    return updateObject(state, {clients: clients});
};

const clientsUpdateEmailSuccess = (state, action) => {
    return updateObject(
        state,
        {
            updateEmailResult: 'success',
            errorMessage: null,
        }
    );
}

const clientsUpdateEmailFail = (state, action) => {
    return updateObject(
        state,
        {
            updateEmailResult: 'fail',
            errorMessage: action.payload,
        }
    );
}

const reducer = ( state = initialState, action ) => {
    switch ( action.type ) {
        case actionTypes.CLIENTS_LOAD_FINISH: return clientLoadFinished(state, action);
        case actionTypes.CLIENTS_SET_ACTIVATION_DONE: return clientsSetActivationDone(state, action);
        case actionTypes.CLIENT_UPDATE_EMAIL_FAILED: return clientsUpdateEmailFail(state, action);
        case actionTypes.CLIENT_UPDATE_EMAIL_SUCCESS: return clientsUpdateEmailSuccess(state, action);
        default:
            return state;
    }
};

export default reducer;
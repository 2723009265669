import {Component} from "react";
import {connect} from "react-redux";
import {  message, Modal } from "antd";
import {
    RiCloseCircleLine,
    RiCheckboxCircleLine
} from "react-icons/ri";
import * as systemActions from "../../../redux/system/systemActions";
import {withTranslation} from "react-i18next";

class HeaderMessage extends Component{

    state = {
        systemVisible: false,
        key: 'axios',
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.loading) {
            this.startLoad();
        } else {
            this.stopLoad();
        }

        if (this.props.error) {
            this.showError(this.props.error);
        }

        if (this.props.notifShow) {
            this.showSystemMessage(this.props.notifMsg);
        }
    }

    startLoad = () => {
        const {t} = this.props;
        message.loading({
            content: t("Action in progress"),
            duration: 0,
            key: this.state.key,
        });
    }

    stopLoad = () => {
        message.destroy(this.state.key);
    }

    showError = (error) => {
        message.error({
            content: error,
            icon: <RiCloseCircleLine className="remix-icon" />,
        }, 4500);
    }


    showSystemMessage = (msg) => {
        const {t} = this.props;

        if (!this.state.systemVisible) {
            this.setState({systemVisible: true});
            let modal = Modal.success({
                content: <h3>{t(msg)}</h3>,
                icon: <RiCheckboxCircleLine className="remix-icon" size={24}/>,
            });

            setTimeout(() => {
                this.props.onShowSystemMessage();
                this.setState({systemVisible: false});
                modal.destroy();
            }, 3 * 1000);
        }
    }

    render () {
        return (<span></span>);
    }

}

const mapStateToProps = state => {
    return {
        loading: state.system.requestLoading,
        error: state.system.requestError,
        notifShow: state.system.notificationShow,
        notifMsg: state.system.notificationMessage
    }
};

const mapDispatchToProps = dispatch => {
    return {
        onShowSystemMessage: () => dispatch(systemActions.systemNotificationEnd()),
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(HeaderMessage));
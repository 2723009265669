export const PROFILE_LOAD = 'PROFILE_LOAD';
export const PROFILE_LOAD_FINISH = 'PROFILE_LOAD_FINISH';
export const PROFILE_LOAD_ERROR = 'PROFILE_LOAD_ERROR';
export const PROFILE_UPDATE = 'PROFILE_UPDATE';
export const PROFILE_GET_NOTIFICATION_SETTINGS = 'PROFILE_GET_NOTIFICATION_SETTINGS';
export const PROFILE_GET_NOTIFICATION_SETTINGS_FINISH = 'PROFILE_GET_NOTIFICATION_SETTINGS_FINISH';
export const PROFILE_SAVE_NOTIFICATION_SETTINGS = 'PROFILE_SAVE_NOTIFICATION_SETTINGS';

export const profileLoad = (token) => {
    return {
        type: PROFILE_LOAD,
        token: token
    }
}

export const profileLoadFinish = (payload) => {
    return {
        type: PROFILE_LOAD_FINISH,
        payload: payload
    }
}

export const profileLoadError = (error) => {
    return {
        type: PROFILE_LOAD_ERROR,
        error: error,
    }
}

export const profileUpdate = (data, token) => {
    return {
        type: PROFILE_UPDATE,
        token: token,
        data: data
    }
}

export const getNotificationSettings = (token) => {
    return {
        type: PROFILE_GET_NOTIFICATION_SETTINGS,
        token: token
    }
}

export const getNotificationSettingsFinish = (data) => {
    return {
        type: PROFILE_GET_NOTIFICATION_SETTINGS_FINISH,
        data: data
    }
}

export const saveNotificationSettings = (token, data) => {
    return {
        type: PROFILE_SAVE_NOTIFICATION_SETTINGS,
        token: token,
        data: data
    }
}


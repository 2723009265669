import {updateObject} from "../../shared/utility";
import * as actionTypes from "./blackListActions";

const initialState = {
    ips: null,
    asns: null,
    isps: null,
    domains : null
};

const blackListLoaded = (state, action) => {
    return updateObject(
        state,
        action.payload
    );
};


const reducer = ( state = initialState, action ) => {
    switch ( action.type ) {
        case actionTypes.BLACKLIST_LOADED: return blackListLoaded(state, action);
        default:
            return state;
    }
};

export default reducer;
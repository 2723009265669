import { put, delay } from "redux-saga/effects";

import axios from "axios";
import * as actions from "./authActions";

export function* authLoginSaga(action) {
    try {
        const response = yield axios.post(
            "/v1/auth/login",
            {
                email: action.email,
                password: action.password
            }
        );
        yield put(actions.loginSuccess(response.data.result));
        yield put(actions.checkAuthTimeout(response.data.result.expires_in));

    } catch (error) {
        yield put(actions.loginFailed(error));
    }
}

export function* logoutSaga(action) {
    yield localStorage.removeItem("token");
    yield localStorage.removeItem("token_type");
    yield localStorage.removeItem("expires_in");
    yield localStorage.removeItem("expireDate");
    yield localStorage.removeItem("menu");
    yield localStorage.removeItem("paid_expiration");
    yield localStorage.removeItem("account_active");
    yield localStorage.removeItem("is_admin");
    yield put(actions.logoutSucceed());
}

export function* checkAuthTimeoutSaga(action) {
    yield delay(action.expirationTime * 1000);
    yield put(actions.logout());
}

export function* authCheckStateSaga(action) {

    const token = yield localStorage.getItem("token");

    if (!token) {
        yield put(actions.logout());
    } else {
        let expires_in = localStorage.getItem("expires_in");
        let token_type = localStorage.getItem("token_type");
        let expireDate = localStorage.getItem("expireDate");
        let menu = localStorage.getItem("menu");
        let account_active = localStorage.getItem("account_active");
        let paid_expiration = localStorage.getItem("paid_expiration");
        let is_admin = JSON.parse(localStorage.getItem("is_admin")) === true;

        if (menu) {
            menu = JSON.parse(menu);
        }

        const expirationDate = yield new Date(expireDate);

        if (expirationDate <= new Date()) {
            yield put(actions.logout());
        } else {
            yield  put(actions.loginSuccess({
                access_token: token,
                expires_in: expires_in,
                token_type: token_type,
                expireDate: expireDate,
                menu: menu,
                paid_expiration: paid_expiration,
                account_active: account_active,
                is_admin: is_admin,
            }));

            yield put(
                actions.checkAuthTimeout(
                    (expirationDate.getTime() - new Date().getTime()) / 1000
                )
            );
        }
    }
}

export function* authRegisterSaga(action) {
    try {
        let domain = window.location.hostname;
        const response = yield axios.post(
            "/v1/auth/register",
            {
                email: action.email,
                password: action.password,
                password_confirmation: action.password_confirmation,
                promo: action.promo,
                telegram: action.telegram,
                domain: domain
            }
        );

        yield put(actions.registerSuccess(response.data.result));
        yield put(actions.checkAuthTimeout(response.data.result.expires_in));
    } catch (error) {
        yield put(actions.registerFailed(error));
    }
}

export function* authRecoverPasswordSaga(action) {
    try {
        let domain = window.location.hostname;
        const response = yield axios.post(
            "/v1/auth/forgot-password",
            {
                email: action.email,
                domain: domain
            }
        );

        yield put(actions.recoverPasswordSuccess(response.data.result));
    } catch (error) {
        yield put(actions.recoverPasswordFailed(error));
    }
}

export function* authResetPasswordSaga(action) {
    try {
        const response = yield axios.post(
            "/v1/auth/reset-password/" + action.token,
            {
                email: action.email,
                password: action.password,
                confirmPassword: action.password_confirmation,
            }
        );

        yield put(actions.resetPasswordSuccess(response.data.result));
        yield put(actions.checkAuthTimeout(response.data.result.expires_in));
    } catch (error) {
        yield put(actions.resetPasswordFailed(error));
    }
}


export function* confirmEmailSaga(action) {
    try {
        let {id, token} = action.payload;

        const response = yield axios.get(
            "v1/auth/email-verification/" + id + "/" + token
        );

        if (response && response.data && response.data.result) {
            yield put(actions.confirmEmailSuccess(response.data.result));
        }

    } catch (error) {
        console.log("FAILED", error);
    }
}


import {put} from "@redux-saga/core/effects";
import * as actions from "./expirationsActions";
import axios from "axios";

export function* loadExpirationsSaga(action) {
    let config = {
        headers: {
            Authorization: "Bearer " + action.token,
            Accept: "application/json"
        }
    };

    try {
        const response = yield axios.get(
            "/v1/expirations",
            config
        );
        yield put(actions.expirationsLoadFinish(response.data.result));

    } catch (error) {

    }
};
